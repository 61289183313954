import gql from 'graphql-tag';

const findPendingApprovalArtist = gql`
query{
  findPendingApproval(type:"artist"){
    data{
      approvalType,
      artist{
        displayName,
        image,
        _id
        user{
          firstName
        }
      }
      }
      }
  }
`;

interface response {
  data: [
    {
      approvalType: string;
      album?: Album;
      artist?: Artist;
    }
  ];
}

export interface findPendingApprovalResponse {
  data: response;
}

export default findPendingApprovalArtist;
