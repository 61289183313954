import gql from 'graphql-tag'

const findSongEarning = gql`

query($limit:Int,$offset:Int,$type:String){
    findSongsWithEarning(offset:$offset, limit:$limit,type:$type){
      total,
      songs{
        title,
          image,
          _id,
          songEarning,
          starfarmEarning,
          playSong,
          costPerPlay
      }
    }
  }
  `


export interface findAlbumManyResponse {
    findAlbumMany: Album[];
    findAlbumWithEarning: AllArtistSchema;
    findSongsWithEarning:AllArtistSchema;
    searchAllResponse: any
    searchUser: any
    searchArtist:any
    searchSong:any
    totalRevenue: totalRevenueInterface
}

export interface findAlbumManyVariables {
    limit: Number;
    offset: Number;
    type: String
}


export default findSongEarning