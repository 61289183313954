import gql from "graphql-tag";

const topArtists = gql`
query($type:String){
    findTopSongAlbumArtist(type:$type,limit: 5){
    name,
    image,
    _id,
     primaryArtist{
_id}
    
    }
  }`

export interface artistSignUpResponse {
    findUsers: StreamerData;
    findAritst: StreamerData;
    findTopSongAlbumArtist: Array<Album>,
}
export interface artistSignUpVariable {
    type: String;
}


export default topArtists; 