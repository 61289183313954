import React, { FC, useContext } from 'react';
import Box from '@material-ui/core/Box';
import NavigationBar from './Navigation';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SearchBar from './SearchBar';
import StarfarmLogo from '../pictures/starfarmlogo.png';
import PendingApprovalBox from './PendingApprovalBox';
import ScheduledJobsContainer from './ScheduledJobsContainer';
import EventMessageContext from 'stores/EventMessage';
import DescriptionAlert from './@ui/DescriptionAlert';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles<Theme>((theme) => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxHeight: '100vh',
    minHeight: '100vh',
    // paddingTop: '2.5%',
    // paddingBottom: '1%',
    backgroundColor: '#02172a',
    color:'white',
  },
  contentDiv: {
    maxHeight: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    direction: 'rtl',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DB0C1',
      borderRadius: '100px',
    },
  },
  scrollableDiv: {
    maxHeight: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9DB0C1',
      borderRadius: '100px',
    },
  },
  content: {
    direction: 'ltr',
    // padding: '20px 50px',
  },
  rightSide: {
    padding: '0px 10%',
  },
}));

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  const alertManager = useContext(EventMessageContext);

  return (
    <div className={classes.mainDiv}>
      <Box borderRight="2px solid #E1EAF1" paddingTop="20px" bgcolor="#192B3B" width="20%">
        <NavigationBar />
      </Box>
      <Box width="100%">
        <Box className={classes.contentDiv} width="100%">
          <div className={classes.content}>
            {alertManager.severityType && <DescriptionAlert />}
            {children}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default observer(Layout);
