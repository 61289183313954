import gql from 'graphql-tag';

const rejectSong = gql`
  mutation rejectSong($songId: MongoID!) {
    rejectededSong: rejectSong(_id: $songId) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface rejectSongResponse {
  rejectededSong: {
    record: Album;
  };
}

export interface rejectSongVariables {
  songId: string;
}

export default rejectSong;
