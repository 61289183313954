import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import FeaturedAlbum from 'components/FeaturedAlbum';
import FeaturedArtist from 'components/FeaturedArtist';
import UserList from 'components/UserList';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PendingApprovalBox from "../components/PendingApprovalBox"
import { useQuery } from '@apollo/client';
import totalRevenue, {
    newUSerResponse,
    newUSerVariable
} from "../core/graphql/queries/findTotalRevenue"
import EarningListStreaming from "../components/EarningListStreaming"
import EarningListTips from 'components/EarningListTips';
import BellIcon from "../pictures/belly.svg"



const EarningPage: FC = (props) => {
    const useStyles = makeStyles<Theme>((theme) => ({
        tabContainer: {
            display: "flex",
            gap: "1rem",
            fontSize: "20px",
            marginTop: '30px',
        },
        header: {
            fontSize: "24px",
        },
        underline: {
            borderBottom: "2px solid #0ED3BF",
            paddingBottom: "1px"
        },
        mainDiv: {
            padding: '20px 50px',
        },
        earnHeader: {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            padding: "60px 50px",
        },
        activeSelector: {
            fontSize: '24px',
            margin: "0",
            fontWeight: 'bold'

        },
        headerRevenue: {
            fontSize: "28px",
            margin: "0"
        },
        revPara: {
            fontSize: "16px",
            fontWeight: 300,
            margin: '20px 0 10px',
            color: "rgba(255, 255, 255, 0.5)",
        }
    }));

    console.log(window.location.search.split("=")[2], 'sdsdsd')
    const [tabs, setTabs] = useState(parseInt(window.location.search.split("=")[2]))
    const [choose, setChoose] = useState(true);
    const classes = useStyles();
    const [type, setType] = useState("week")
    const [earn, setEarn] = useState<number | null>(0);
    const [tip, setTip] = useState<number | null>(0);
    const { data, loading } = useQuery<newUSerResponse>(totalRevenue, {
        variables: {
            type: type
        }

    })
    useEffect(() => {
        setEarn(data?.totalRevenue?.byPlay??" ")
        setTip(data?.totalRevenue?.byTip??" ")
    }, [data])

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value);
    };

    const history = useHistory()
    const handleNotification = () => {
        history.push("/notifications")
    }






    return (
        <>

            <Box display="flex" className={classes.earnHeader} flexDirection="row" justifyContent="space-between" alignItems="center" mb={5}>
                <div>
                    <Typography
                        variant={choose ? 'h4' : 'h6'}
                        className={classes.activeSelector}
                    >
                        Earnings
                    </Typography>
                    <p className={classes.revPara}>
                        Total Revenue
                    </p>
                    <Typography
                        variant='h6'
                        className={classes.headerRevenue}
                    >
                        $ {data?.totalRevenue?.total}
                    </Typography>
                </div>
                <div style={{ display: 'block' }}>
                    {/* <div style={{ cursor: 'pointer',textAlign:'end',marginBottom:'20px' }} onClick={handleNotification}>
                <img src={BellIcon} />
              </div> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ margin: '0 2px 3px', fontSize: '14px', color: "#3D7670" }}>
                            Display data for
                        </p>
                        <FormControl variant="standard" sx={{ m: 1, width: 150 }} style={{ fontSize: '12px' }}>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={type}
                                onChange={handleChange}
                                label="Age"
                            >
                                <MenuItem value={"week"}>Past 7 Days</MenuItem>
                                <MenuItem value={"month"}>Past Month</MenuItem>
                                <MenuItem value={"year"}>Past Year</MenuItem>
                                <MenuItem value={"allTime"}>All Time</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Box>
            <Box display='flex' style={{ gap: "3rem", padding: "0px 50px" }}>
                <div onClick={() => {
                    history.push({
                        search: `?page=${window.location.search.split("=")[1].split("&")[0]}&tabs=${1}`
                    })
                    setTabs(1)
                }
                }>
                    <h2 style={{ cursor: "pointer" }} className={tabs === 1 ? classes.underline : " "}>
                        Earnings from Streaming
                    </h2>
                </div>
                <div onClick={() => {
                    history.push({
                        search: `?page=${window.location.search.split("=")[1].split("&")[0]}&tabs=${2}`
                    })
                    setTabs(2)
                }
                }>
                    <h2 style={{ cursor: "pointer" }} className={tabs === 2 ? classes.underline : " "}>
                        Earnings from Tip
                    </h2>
                </div>
            </Box>
            <Box padding="0px 50px">
                {tabs === 1 ? <EarningListStreaming type={type}  /> : <EarningListTips type={type}  />}
            </Box>

            {/* </Box> */}
            {/* </Box> */}

        </>
    );
};

export default EarningPage;
