import { useState } from "react"
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Root from 'Root';
import theme from 'theme';
import { fetchToken, onMessageListener } from "./firebaseConfig";

function App() {
  const [message, setMessage] = useState();

  fetchToken((token: string) => {
    console.log('token => ', token)
  });

  onMessageListener()
    .then((payload: any) => {
      console.log('notif => ', payload)
      setMessage(payload.notification);
    })
    .catch(err => console.log('failed: ', err));

  function onSnackbarClose() {
    // @ts-ignore
    setMessage(null);
  }

  return (
    <>
     <div className="App">
      {Boolean(message) && (
        <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={Boolean(message)}
        onClose={onSnackbarClose}
        // @ts-ignore
        message={message?.body}
        action={
          <IconButton size='small' aria-label='close' color='inherit' onClick={onSnackbarClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      />)}
    </div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Root />
      </ThemeProvider>
    </>
  );
}

export default App;
