import gql from 'graphql-tag';

const findJobHistoryMany = gql`
  query {
    jobHistory: findJobHistoryMany(sort: CREATEDAT_DESC) {
      job
      status
      responseMessage
      _id
      createdAt
    }
  }
`;

export interface findJobHistoryManyResponse {
  jobHistory: JobHistory[];
}

export default findJobHistoryMany;
