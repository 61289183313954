import { useQuery } from '@apollo/client';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import StarfarmLogo from '../pictures/starfarmlogo.png';
import dashboad from "../pictures/dashboad.svg"
import star from "../pictures/star.svg"
import earning from "../pictures/earning.svg"
import headphone from "../pictures/headphone.svg"
import jobs from "../pictures/jobs.svg"
import approval from "../pictures/approval.svg"
import dashboadw from "../pictures/Dashboardw.svg"
import starw from "../pictures/Artistsw.svg"
import jobsw from "../pictures/jobw.svg"
import headphonew from "../pictures/Streamersw.svg"
import earningw from "../pictures/earnw.svg"
import approvalw from "../pictures/Approvalsw.svg"
import ProfileSettingsButton from './ProfileSettingsButton';
import totalNumberOfUser, {
  totalNumberOfUserResponse,
} from 'core/graphql/queries/totalNumberOfUsers';
import usePlayer from 'pages/PlayerContext';


const NavigationBar: FC = () => {
  const player=usePlayer();
  const useStyles = makeStyles<Theme>((theme) => ({
    starFarmBackground: {
      position: 'relative',
      background: `url("${StarfarmLogo}")`,
      width: 80,
      height: 80,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginBottom: '10px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menuOptions: {
      width: "100%",
      display: 'flex',
      alignItems: "center",
      position: "relative",
      left: "30px",
      marginBottom: '30px',
    },
    navLink: {
      color: '#455A6D',
      marginLeft: '20px',
      lineHeight: 'none',
      fontSize: '18px',
      '&:hover': {
        color: '#455A6D',
        cursor: 'pointer',
      },
    },
    companyName: {
      marginBottom: "50px",
      fontSize: '20px',
    },
    logOutBtn: {
      position: "absolute",
      bottom: '15px',
      width: "15%",
    },
    selected: {
      color: "white",
      backgroundColor: '#3D7670',
      padding: "15px 0",
      borderRadius: "7px",
    }
  }));

  const [selectedMenu, setSelectedMenu] = useState('')
  
  useEffect(() => {
    setSelectedMenu(window.location.pathname.split("/")[1])
  }, [])

  const classes = useStyles();
  const history = useHistory();

  return (
    <nav>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        onClick={()=>player.pause()}
      >
        <Box
          onClick={() => {history.push('/')
          setSelectedMenu('dashboard')
        }}
          className={classes.starFarmBackground}
        >
        </Box>
        <Typography
          className={classes.companyName}
          variant="h3"
        >
          StarFarm
        </Typography>
        <Box className={selectedMenu === 'dashboard' || "" ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === "dashboard" || "" ? dashboadw : dashboad}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "dashboard" || "" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('dashboard')
              history.push('/')
            }}
          >
            Dashboard
          </Typography>
        </Box>
        <Box className={selectedMenu === 'artist' ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === 'artist' ? starw : star}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "artist" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('artist')
              history.push('/artist/?page=1')
            }}
          >
            Artists
          </Typography>
        </Box>
        <Box className={selectedMenu === "streamers" ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === "streamers" ? headphonew : headphone}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "streamers" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('streamers')
              history.push('/streamers?page=1')
            }
            }
          >
            Streamers
          </Typography>
        </Box>
        <Box className={selectedMenu === 'earning' ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === 'earning' ? jobsw : earning}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "earning" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('earning')
              history.push('/earning/?page=1&tab=1')
            }
            }
          >
            Earnings
          </Typography>
        </Box>
        <Box className={selectedMenu === 'jobs' ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === 'jobs' ? earningw : jobs}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "jobs" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('jobs')
              history.push('/jobs')
            }
            }
          >
            Scheduled Jobs
          </Typography>
        </Box>
        <Box className={selectedMenu === 'approval' ? classes.selected : " "} style={{
          width: "100%",
          display: 'flex',
          alignItems: "center",
          position: "relative",
          paddingLeft: "30px",
          marginBottom: '30px',
        }}>
          <img src={selectedMenu === 'approval' ? approvalw : approval}>
          </img>
          <Typography
            className={classes.navLink}
            variant="h4"
            style={{ color: selectedMenu === "approval" ? "white" : '#455A6D' }}
            onClick={() => {
              
              setSelectedMenu('approval')
              history.push('/approval/?tab=1')
            }
            }
          >
            Approvals
          </Typography>
        </Box>
        <Box className={classes.logOutBtn}>
          <ProfileSettingsButton />
        </Box>
      </Box>
    </nav>
  );
};

export default NavigationBar;
