import React, { FC } from 'react';

interface IUnauthorizedContentMessageProps {}

const UnauthorizedContentMessage: FC<IUnauthorizedContentMessageProps> = (
  props
) => {
  return <div>Unauthorized Content</div>;
};

export default UnauthorizedContentMessage;
