import gql from 'graphql-tag';

const featureArtist = gql`
  mutation featureArtist($id: MongoID!) {
    featuredArtist: featureArtist(_id: $id) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface featureArtistResponse {
  featuredArtist: {
    record: Artist;
  };
}

export interface featureArtistVariables {
  id: string;
}

export default featureArtist;
