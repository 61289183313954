import { DateTime } from 'luxon';

const getTimeFromNow = (timestamp: string) => {
  const days = Math.floor(DateTime.fromISO(timestamp).diffNow('days').days);
  const hours = Math.floor(DateTime.fromISO(timestamp).diffNow('hours').hours);
  const minutes = Math.floor(
    DateTime.fromISO(timestamp).diffNow('minutes').minutes
  );

  if (days > 0) {
    return days === 1 ? days + ' day' : days + ' days';
  }

  if (hours > 0) {
    return hours + ' hr ' + (minutes - hours * 60) + ' min';
  }

  return minutes + ' min';
};

export default getTimeFromNow;
