import { action, observable } from 'mobx';

class ConfirmAction {
  @observable
  public confirmOpen: boolean = false;

  @observable
  public title: string = '';

  @observable
  public message: string = '';

  @action
  public setConfirmOpen = (open: boolean) => {
    this.confirmOpen = open;
  };

  @action
  public setTitle = (title: string) => {
    this.title = title;
  };

  @action
  public setMessage = (message: string) => {
    this.message = message;
  };
}

export default ConfirmAction;
