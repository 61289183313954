import React, { FC, useMemo, useState } from 'react';
import SongList from 'components/SongList';
import { Box } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import FIND_SONG_BY_ID, {
  FIND_SONG_BY_IDResponse,
  FIND_SONG_BY_IDVariables,
} from 'core/graphql/queries/findSongById';
import { makeStyles, Theme } from '@material-ui/core/styles';
import backArrow from "../pictures/backArrow.svg"
import MoreButton from '../components/@ui/MoreButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { MenuItem, ListItemText } from '@material-ui/core';
import ConfirmAction from 'stores/ConfirmAction';
import { useApolloClient } from '@apollo/client';
import SongActionMenuManager from 'stores/SongActionMenu';
import ConfirmActionPopUp from '../components/@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';



interface AlbumProps extends RouteComponentProps<{ id: string }> { }

const SongPage: FC<AlbumProps> = ({ match }) => {
  const { data, loading } = useQuery<
    FIND_SONG_BY_IDResponse,
    FIND_SONG_BY_IDVariables
  >(FIND_SONG_BY_ID, { variables: { songId: match.params.id } });

  const useStyles = makeStyles<Theme>((theme) => ({
    backBtn: {
      position: 'relative',
      top: "3.4%",
      left: "4%",
      display: "flex",
      gap: "0.3rem",
      alignItems: "center",
      cursor: 'pointer',
    },
    albumCard: {
      height: "150px",
      width: "150px",
      borderRadius: "2px",
      cursor: 'pointer',
    },
    albumInfo: {
      color: "#3F647A",
      fontSize: '14px',
      lineHeight: '0',
      margin: "0",
    },
    imageCard: {
      width: '50px',
      height: '50px',
    },
    icon: { fill: theme.palette.primary.main },

  }));

  const classes = useStyles()

  const history = useHistory();

  const hash = useMemo(() => {
    return history.location.hash;
  }, [history.location]);

  const goBack = () => {
    history.goBack()
  }

  const client = useApolloClient();
  let id = window.location.pathname.split('/')[2]

  const [SongActionMenuStore] = useState(
    new SongActionMenuManager(client, id)
  );
  const [confirmActionStore] = useState(new ConfirmAction());


  const handleSuspend = (song: any) => {
    confirmActionStore.setTitle('Suspend');
    confirmActionStore.setMessage(
      `Are you sure you want to suspend ${song.name}?`
    );
    SongActionMenuStore.setActionToRun(SongActionMenuStore.suspendSong);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleEnable = (song: any) => {
    confirmActionStore.setTitle('Enable');
    confirmActionStore.setMessage(
      `Are you sure you want to enalbe ${song.name}?`
    );
    SongActionMenuStore.setActionToRun(SongActionMenuStore.enableSong);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleDisable = (song: any) => {
    confirmActionStore.setTitle('Disable');
    confirmActionStore.setMessage(
      `Are you sure you want to disable ${song.name}?`
    );
    SongActionMenuStore.setActionToRun(SongActionMenuStore.disableSong);
    confirmActionStore.setConfirmOpen(true);
  };

  return (
    <>

      <Box position="relative">
        <div className={classes.backBtn} onClick={goBack}>
          <img src={backArrow} />
          <h2>
            Back
          </h2>
        </div>
      </Box>
      <Box padding="20px 12px 0 80px">
        <Box display='flex'>
          <h2 style={{ fontSize: "24px" }}>
            {data?.song?.name}
          </h2>
        </Box>
        <Box marginTop="20px" display="flex" style={{ gap: "2rem" }}>
          <div className={classes.albumCard}>
            <img src={data?.song?.image} style={{ width: "100%" }} />
          </div>
          <div style={{ display: 'flex',alignItems:'center', gap:"1rem" }}>
            <div>
              <h3 style={{ fontSize: '20px', lineHeight: '0' }}>
                {data?.song?.primaryArtist?.displayName}
              </h3>
              <h3 style={{ fontSize: '14px', lineHeight: '1' }}>
                {data?.song?.genre}
              </h3>
            </div>
            <MoreButton dropDownIcon={<MoreHorizIcon className={classes.icon} />}>
              {data?.song?.status === 'APPROVED' && (
                <MenuItem onClick={() => handleSuspend(data?.song)}>
                  <ListItemText style={{ color: '#02172A' }} primary="Suspend" />
                </MenuItem>
              )}
              {data?.song?.disabled === false ? (
                <MenuItem onClick={() => handleEnable(data?.song)}>
                  <ListItemText style={{ color: '#02172A' }} primary="Enable" />
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleDisable(data?.song)}>
                  <ListItemText style={{ color: '#02172A' }} primary="Disable" />
                </MenuItem>
              )
              }
            </MoreButton>
          </div>
        </Box>
        <div style={{ margin: "20px 0" }}>
        </div>
        <div style={{ marginTop: "30px", display: 'flex', gap: '2rem' }}>
          <div>
            <h2 style={{ fontSize: '20px' }}>
              Plays
            </h2>
            <h3 style={{ fontSize: '14px' }}>
              {data?.song?.plays}
            </h3>
          </div>
          <div>
            <h2 style={{ fontSize: '20px' }}>
              Cost Per Play
            </h2>
            <h3 style={{ fontSize: '14px' }}>
              $ 0. 0{data?.song?.costPerPlay}
            </h3>
          </div>
        </div>
      </Box>
      <ConfirmActionPopUp
        context={confirmActionStore}
        actionOnConfirm={SongActionMenuStore.actionToRun!}
      />
    </>
  );
};

export default observer(SongPage);