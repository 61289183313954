import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import React, { FC, createContext, useEffect } from 'react';
import { useMemo } from 'react';
import PlayerStore from './Player';

interface IPlayerContextProviderProps {}

export const PlayerContext = createContext(new PlayerStore());

const PlayerContextProvider: FC<IPlayerContextProviderProps> = ({
  children,
}) => {
  const client:any = useApolloClient();

  const player = useMemo(() => {
    return new PlayerStore(client);
  }, [client]);
  return (
    <PlayerContext.Provider value={player}>{children}</PlayerContext.Provider>
  );
};

export default PlayerContextProvider;
