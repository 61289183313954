import gql from 'graphql-tag';

const approveAlbum = gql`
  mutation approveAlbum($albumId: String) {
    approveAlbum(albumId: $albumId) {
      _id
      status
    }
  }
`;

export interface approveAlbumResponse {
  album: Album;
}

export interface approveAlbumVariables {
  albumId: string;
}

export default approveAlbum;
