import Box from '@material-ui/core/Box/Box';
import Typography, {
  TypographyProps,
} from '@material-ui/core/Typography/Typography';
import React, { FC } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles<Theme>((theme) => ({
  message: {
    fontWeight: 'bold',
    color: '#455A6D',
    marginTop: '50px',
  },
}));

interface IEmptyDataMessageProps extends TypographyProps {
  icon?: any;
  message: string;
  noPadding?: boolean;
}

const EmptyDataMessage: FC<IEmptyDataMessageProps> = (
  props: IEmptyDataMessageProps
) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={props.noPadding ? '0px' : '25% 20%'}
    >
      {props.icon}
      <Typography {...props} className={classes.message} align="center">
        {props.message}
      </Typography>
    </Box>
  );
};

export default EmptyDataMessage;
