import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: "AIzaSyCZC_3YbLQqoTL6faMVewL9HKrckh03yAQ",
  authDomain: "starfarm-20e4e.firebaseapp.com",
  projectId: "starfarm-20e4e",
  storageBucket: "starfarm-20e4e.appspot.com",
  messagingSenderId: "556686320746",
  appId: "1:556686320746:web:77d29672e7126c62ed5613"
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const fetchToken = (setTokenFound) => {
  getToken(messaging, { vapidKey: 'BD__QsRwDNdO0ABIYbeOqpenGPkyH5lm3cUhbxODMCF2eE9qmfOTTqs2GBkgw5KYljpKPmXA3qejDailMTf4qrk' })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        setTokenFound(currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(null)
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token: ', err)
    })
}

export const onMessageListener = () => 
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
