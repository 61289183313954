import React, { FC, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { CustomPagination } from './@ui/Pagination';
import { useHistory } from 'react-router-dom';
import StarsIcon from '@material-ui/icons/Stars';
import StreamerItem from './StreamerItem';
import VerticalList from './VerticalList';
import findAllUsers, {
  findAllUsersResponse,
} from 'core/graphql/queries/findAllUsers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from "../pictures/dummyAvatar.svg"
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import SearchUSer, {
  searchAllResponse
} from "../core/graphql/queries/searchUsers"
import Sort from "../pictures/sort.svg"

const limit = 10;


interface propsItem {
  type: String
}

const StreamerList: FC<propsItem> = ({
  type
}) => {




  const [page, setPage] = React.useState(parseInt(window.location.search.split("=")[1]));
  const [search, setSearch] = React.useState("");
  const [count, setCount] = React.useState(0)
  const [sort, SetSort] = React.useState(false)
  const QueryMultiple = () => {
    const res1 = useQuery<findAllUsersResponse>(
      findAllUsers,
      {
        variables:
          { limit: limit, offset: limit * page - limit, type: type },

      }
    );
    const res2 = useQuery<searchAllResponse>(
      SearchUSer, {
      variables: {
        text: search,
        type: type
      }
    }
    )

    return [res1, res2]
  }

  const [
    { loading: loading1, data: data1, refetch },
    { loading: loading2, data: data2 },
  ] = QueryMultiple()


  const [list, SetList] = React.useState(data1 ? data1?.allUserList?.users : undefined)
  const [listSearch, SetListSearch] = React.useState(data2 ? data2?.searchUser : undefined)


  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    history.push({
      search: `?page=${value}`
    })
  };

  const history = useHistory()

  const handleClick = (id: any) => {
    history.push(`/streamer/${id}`)
  }

  useEffect(() => {
    if (data1 && data1?.allUserList.total % 10 > 0) {
      setCount(Math.floor(data1 ? data1?.allUserList.total / 10 : 1) + 1)
      console.log(count, 'sdsd')
    }
    else {
      setCount(Math.floor(data1 ? data1?.allUserList?.total / 10 : 1))
    }

    refetch()
    SetList(data1?.allUserList?.users)
    SetListSearch(data2?.searchUser)
  }, [page, search, type, count, data1, data2]);

  const handleInput = (event: any) => {
    setSearch(event.target.value)
  };



  // console.log(data1?.allUserList.total%10,"hellos")

  const useStyles = makeStyles<Theme>((theme) => ({
    image: {
      width: '55px',
      height: '55px',
    },
    artistContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem'
    },
    SearchField: {
      display: "flex",
      alignItems: "center",
      gap: "0.3rem",
      marginBottom: "20px"
    },
    ClearBtn: {
      background: 'none',
      outline: 'none',
      border: 'none',
      color: "#fff",
      fontWeight: 600,
      display: search ? "block" : "none",

    }


  }));

  const handleSort = () => {
    SetSort(!sort)
    sort ? SetList(data1?.allUserList?.users.slice().sort((a: any, b: any) => b.totalSpent - a.totalSpent)) : SetList(data1?.allUserList?.users.slice().sort((a: any, b: any) => a.totalSpent - b.totalSpent))
    sort ? SetListSearch(data2?.searchUser.slice().sort((a: any, b: any) => b.totalSpent - a.totalSpent)) : SetListSearch(data2?.searchUser.slice().sort((a: any, b: any) => a.totalSpent - b.totalSpent))

  }


  const classes = useStyles();

  // let count = data1 ? Math.floor(data1?.allUserList?.total / 10) : 1;

  return (
    <>
      <div className={classes.SearchField}>
        <h2 style={{ color: "#3D7670" }}>Search</h2>
        <TextField className={classes.SearchBox} id="outlined-basic" variant="outlined" value={search} onInput={(e) => handleInput(e)} />
        <button className={classes.ClearBtn} style={{ cursor: 'pointer' }} onClick={() => {
          setSearch("")
        }}>
          Clear
        </button>
      </div>

      {!search ?
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow style={{ color: 'white !important' }}>
                  <TableCell width="5%">
                    #
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', gap: "0.5rem" }}>
                      Spent($)
                      <img src={Sort} onClick={handleSort} style={{ cursor: 'pointer' }} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list && list.map((val: any, idx: number) => (
                  <TableRow onClick={() => handleClick(val._id)} style={{ cursor: 'pointer' }}>
                    <TableCell>
                      {((page - 1) * 10) + ++idx}
                    </TableCell>
                    <TableCell width={'40%'}>
                      <>
                        <div className={classes.artistContainer}>
                          <img src={Avatar} className={classes.image} />
                          {`${val.firstName} ${val.lastName}`}
                        </div>
                      </>
                    </TableCell>
                    <TableCell width={'40%'}>
                      <>
                          {val.email}
                      </>
                    </TableCell>
                    <TableCell className='colorBlue'>
                      {val.totalSpent}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ marginTop: "30px" }}>
            <Pagination count={count} page={page} onChange={handleChange} />
          </div>

        </>

        : <>
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow className={classes.thead}>
                    <TableCell style={{ color: 'white' }}>
                      #
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      Name
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', gap: "0.5rem" }}>
                        Spent($)
                        <img src={Sort} onClick={handleSort} style={{ cursor: 'pointer' }} />
                      </div>
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listSearch && listSearch.map((title: any, idx: number) => (
                    <TableRow>
                      <TableCell style={{ color: 'white' }}>
                        {((page - 1) * 10) + ++idx}
                      </TableCell>
                      <TableCell>
                        {title.firstName} {title.lastName}
                      </TableCell>
                      <TableCell className='colorBlue'>
                      {title.totalSpent}
                    </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => handleClick(title._id)}>
                        View
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>}
    </>
  );
};

export default StreamerList;
