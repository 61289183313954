import gql from 'graphql-tag';

const rejectArtist = gql`
  mutation rejectArtist($artistId: String) {
    rejectArtist(artistId: $artistId) {
      _id
      status
    }
  }
`;

export interface rejectArtistResponse {
  artist: Artist;
}

export interface rejectArtistVariables {
  artistId: string;
}

export default rejectArtist;
