import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import findScheduledJobs, {
  findScheduledJobsResponse,
} from 'core/graphql/queries/findScheduledJobs';
import { makeStyles, Theme } from '@material-ui/core/styles';
import NextScheduledJobItem from '../components/NextScheduledJobItem';
import ScheduledJobItem from '../components/ScheduledJobItem';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "../components/@ui/custom.css"
import BellIcon from "../pictures/belly.svg"


const useStyles = makeStyles<Theme>((theme) => ({
  textButton: {
    padding: '2px',
    color: '#455A6D',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mainDiv: {
    padding: '20px 50px',
  }
}));

const ScheduledJobsContainer: FC = () => {
  const { data, loading } = useQuery<findScheduledJobsResponse>(
    findScheduledJobs,
    {
      pollInterval: 60000,
    }
  );

  const history = useHistory();

  const classes = useStyles();

  const jobs = useMemo(() => {
    if (!data) {
      return [];
    }

    console.log(
      DateTime.fromISO(data.scheduledJobs[0].nextRunDate!).toMillis()
    );

    const jobs = data.scheduledJobs
      .slice()
      .sort(
        (a, b) =>
          DateTime.fromISO(a.nextRunDate!).toMillis() -
          DateTime.fromISO(b.nextRunDate!).toMillis()
      );

    return jobs;
  }, [data]);


  const handleNotification = () => {
    history.push("/notifications")
  }


  return (
    <>
      <div className={classes.mainDiv}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
          mt={7}
        >
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <div>
              <Typography
                variant="body1"
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  marginBottom: '20px'
                }}
              >
                Scheduled Jobs
              </Typography>
            </div>
            {loading && (
              <CircularProgress
                style={{
                  height: '15px',
                  width: '15px',
                  color: '#455A6D',
                  marginLeft: '30px',
                }}
              />

            )}
            {/* <div style={{ cursor: 'pointer', textAlign: 'end', marginBottom: '20px' }} onClick={handleNotification}>
              <img src={BellIcon} />
            </div> */}
          </Box>
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead>
              <TableRow style={{ color: 'white !important' }}>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.scheduledJobs.map((job, idx) => (
                <>
                  <TableRow>
                    <TableCell>
                      {++idx}
                    </TableCell>
                    <TableCell>
                      <ScheduledJobItem key={job.name} job={job} />
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ScheduledJobsContainer;
