import topAlbum, {
    artistSignUpResponse
} from "../core/graphql/queries/findTop5Album"
import { useQuery } from '@apollo/client';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory  } from "react-router-dom";
import findTopArtist from "../core/graphql/queries/findTopArtist"
import { useEffect } from "react";
import Avatar from "../pictures/dummyAvatar.svg"


export const TopSong = (type: any) => {
    const useStyles = makeStyles<Theme>((theme) => ({
        imageContainer: {
            width:'100px',
            height:'100px',
            borderRadius:'50%',
        },
        list:{
            display:'flex',
            gap:"0.5rem",
            // justifyContent:"center",
            // alignItems:'center'
        },
        text:{
            fontSize:'14px',
            wordBreak:"break-word"
        }
    }));

    const history = useHistory()

    const classes = useStyles()

    const { data, loading,refetch } = useQuery<artistSignUpResponse>(findTopArtist, {
        variables: type
    })

    console.log(data)

    useEffect(()=>{
        refetch()
    },[type])
   

    return (
        <>
            <div className={classes.list}>
                {data?.findTopArtist && data?.findTopArtist.map((album: any) => (
                    <div style={{width:'120px',textAlign:'center',cursor:'pointer'}} onClick={()=>history.push(`/artist/${album._id}`)}>
                        <img src={album.image?album.image:Avatar} className={classes.imageContainer} />
                        <h3 className={classes.text}>
                            {album.artistName}
                        </h3>
                    </div>
                ))}
            </div>
        </>
    )
}