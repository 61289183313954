import { FC, useState, useEffect, useLayoutEffect } from 'react';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import FeaturedAlbum from 'components/FeaturedAlbum';
import FeaturedArtist from 'components/FeaturedArtist';
import StarsIcon from '@material-ui/icons/Stars';
import TopSongOfTheWeek from "../components/TopSongOfTheWeek"
import FlaggedContent from 'containers/FlaggedContent';
import { useHistory } from 'react-router-dom';
import AddedAlbumByWeek from 'components/AddedAlbumsByWeek';
import AddedAlbumByMonth from 'components/AddedAlbumByMonth';
import TopArtistAlbumByWeek from 'components/TopArtistAlbumByWeek';
import TopArtistAlbumByMonth from 'components/TopArtistAlbumByMonth';
import RegisteredUserWeek from "components/RegisteredUserWeek";
import { useQuery } from '@apollo/client';
import userSignUp, {
  userSignupResponse
} from "../core/graphql/queries/findUserSignup"
import artistSignUp, {
  artistSignUpResponse
} from "../core/graphql/queries/findArtistSignup"
import topArtists from "../core/graphql/queries/findTop5Album"
import findTopArtist from "../core/graphql/queries/findTopArtist"
import AlbumsByGenres, {
  albumGenreResponse,
  getAlbumsByGenresVariables,
} from "../core/graphql/queries/findAlbumByGenere"
import findRevenue, {
  findRevenueResponse
} from "../core/graphql/queries/findRevenue"
import { TestChart } from 'components/@ui/Chart';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TopAlbums } from "../components/Top5Album"
import { TopArtist } from "../components/Top5Artist"
import ListContainer from "../components/@ui/ListContainer"
import { TopSong } from "../components/TopSong"
// import  BellIcon  from "../pictures/belly.svg"

const useStyle = makeStyles<Theme>((theme) => ({
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: '20px',
  },
  title1: { color: 'white', fontWeight: 'bold' },
  inactiveSelector: { color: '#455A6D', fontWeight: 'bold' },
  activeSelector: { color: '#3D7670', fontWeight: 'bold' },
  underline: {
    borderBottom: "2px solid #0ED3BF",
    paddingBottom: "1px"
  },
  chartHead: {
    fontSize: '20px',
    paddingLeft: '20px'
  }
}));

const Dashboard: FC = (props) => {
  const [choose, setChoose] = useState(true);
  const [toggleMonthWeek, setToggleMonthWeek] = useState(true);
  const [chooseTopAlbums, setChooseTopAlbums] = useState(true);
  const listArray = ["Reggae", "Indie Rock", "New Age"]
  const [tabs, setTabs] = useState('album')
  const [type, setType] = useState("week")
  const [typeRevenue, setTypeRevenue] = useState("week")
  const [typeRevenueExact, setTypeRevenueExact] = useState("week")
  const [typeuser, setTypeUser] = useState("week")
  const [typeartist, setTypeArtist] = useState("week")
  const [exactType, setExactType] = useState("week")
  const [exactTypeArt, setExactTypeArt] = useState("week")

  const QueryMultiple = () => {
    const res1 = useQuery<userSignupResponse>(userSignUp, {
      variables: {
        type: exactType
      }
    });
    // const res2 = useQuery<albumGenreResponse>(AlbumsByGenres,{ variables: { genres: listArray  } });
    const res2 = useQuery<artistSignUpResponse>(artistSignUp, {
      variables: {
        type: exactTypeArt
      }
    })
    const res3 = useQuery<artistSignUpResponse>(topArtists, {
      variables: {
        type: type
      }

    })
    const res4 = useQuery<artistSignUpResponse>(findTopArtist, {
      variables: {
        type: type
      }
    });

    const res5 = useQuery<findRevenueResponse>(findRevenue, {
      variables: {
        type: typeRevenueExact
      }
    });
    return [res1, res2, res3, res4, res5];
  }


  const history = useHistory()



  const [
    { loading: loading1, data: data1 },
    { loading: loading2, data: data2 },
    { loading: loading3, data: data3 },
    { loading: loading4, data: data4 },
    { loading: loading5, data: data5 },
  ] = QueryMultiple()

  console.log(data1)


  const handleChange = (event: SelectChangeEvent) => {
    setTypeUser(event.target.value)
    setTypeArtist(event.target.value)
    setType(event.target.value);
    setExactType(event.target.value)
    setExactTypeArt(event.target.value)
    setTypeRevenue(event.target.value)
    setTypeRevenueExact(event.target.value)
  };

  const handleChangeUser = (event: SelectChangeEvent) => {
    setTypeUser(event.target.value);
    setExactType(event.target.value)

  };

  const handleChangeArtist = (event: SelectChangeEvent) => {
    setTypeArtist(event.target.value);
    setExactTypeArt(event.target.value)

  };

  const handleRevenueChange = (event: SelectChangeEvent) => {
    setTypeRevenue(event.target.value)
    setTypeRevenueExact(event.target.value)

  };

  // const handleNotification = ()=>{
  //   history.push("/notifications")
  // }



  const classes = useStyle();

  const streamer = data1?.findUsers?.dates.map((id: any, index: any) => {
    return {
      label: data1?.findUsers?.dates[index],
      UserSignup: data1?.findUsers?.data[index]
    }
  });

  const artist = data2?.findAritst?.dates.map((id: any, index: any) => {
    return {
      label: data2?.findAritst?.dates[index],
      ArtistSignup: data2?.findAritst?.data[index]
    }
  });

  const revenue = data5?.revenueDayWeekMonthYear?.date.map((id: any, index: any) => {
    return {
      label: data5?.revenueDayWeekMonthYear?.date[index],
      TotalRevenue: data5?.revenueDayWeekMonthYear?.data[index]
    }
  })

  let comp
  if (tabs === 'album') {
    comp = <TopAlbums type={type} />
  }
  else if (tabs === 'artist') {
    comp = <TopSong type={type} />
  }
  else {
    comp = <TopArtist type={type} />
  }



  return (
    <>
      <Box display="flex" flexDirection="column" padding="20px 50px" marginBottom="20px">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{display:'flex', alignItems: 'center' }}>
            <p style={{ margin: '0 2px 3px', fontSize: '14px', color: "#3D7670" }}>
              Display data for
            </p>
            <FormControl variant="standard" sx={{ m: 1, width: 120 }} style={{ fontSize: '12px' }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={type}
                onChange={handleChange}
                label="Age"
              >
                <MenuItem value={"week"}>Past 7 Days</MenuItem>
                <MenuItem value={"month"}>Past Month</MenuItem>
                <MenuItem value={"year"}>Past Year</MenuItem>
                <MenuItem value={"allTime"}>All Time</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div style={{cursor:'pointer'}} onClick={handleNotification}>
            <img src={BellIcon} />
          </div> */}

        </div>
        <div style={{ marginTop: "15px" }}>
          <h2 style={{ fontSize: '22px' }}>
            Quick Overview
          </h2>
        </div>

        <Box display="flex" style={{ gap: "2rem" }} marginBottom="20px">
          <Box width="100%" bgcolor="#192B3C" borderRadius="10px" >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3 className={classes.chartHead}>
                Total Revenue($)
              </h3>
              <FormControl variant="standard" sx={{ m: 1, width: 120 }} style={{ fontSize: '12px' }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={typeRevenue}
                  onChange={handleRevenueChange}
                  label="Age"
                >
                  <MenuItem value={"week"}>Past 7 Days</MenuItem>
                  <MenuItem value={"month"}>Past Month</MenuItem>
                  <MenuItem value={"year"}>Past Year</MenuItem>
                  <MenuItem value={"allTime"}>All Time</MenuItem>
                </Select>
              </FormControl>
            </div>
            <ResponsiveContainer width="95%" aspect={3}>
              <LineChart data={revenue}>
                <XAxis dataKey='label' />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey='TotalRevenue' stroke="#3D7670" strokeWidth="3px" activeDot={{ r: 5 }} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>


        <div style={{ display: "flex", gap: '1rem' }}>
          <Box display="flex" style={{ gap: "2rem", flexGrow: 1 }} marginBottom="20px">
            <Box width="100%" bgcolor="#192B3C" borderRadius="10px" >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className={classes.chartHead}>
                  Total Streamer Signup
                </h3>
                <FormControl variant="standard" sx={{ m: 1, width: 120 }} style={{ fontSize: '12px' }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={typeuser}
                    onChange={handleChangeUser}
                    label="Age"
                  >
                    <MenuItem value={"week"}>Past 7 Days</MenuItem>
                    <MenuItem value={"month"}>Past Month</MenuItem>
                    <MenuItem value={"year"}>Past Year</MenuItem>
                    <MenuItem value={"allTime"}>All Time</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <ResponsiveContainer width="95%" aspect={3}>
                <LineChart data={streamer}>
                  <XAxis dataKey='label' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey='UserSignup' stroke="#3D7670" strokeWidth="3px" activeDot={{ r: 5 }} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Box>

          <Box display="flex" style={{ gap: "2rem", flexGrow: 1 }} marginBottom="20px" >
            <Box width="100%" bgcolor="#192B3C" borderRadius="10px">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className={classes.chartHead}>
                  Total Artist Signup
                </h3>
                <FormControl variant="standard" sx={{ m: 1, width: 120 }} style={{ fontSize: '12px' }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={typeartist}
                    onChange={handleChangeArtist}
                    label="Age"
                  >
                    <MenuItem value={"week"}>Past 7 Days</MenuItem>
                    <MenuItem value={"month"}>Past Month</MenuItem>
                    <MenuItem value={"year"}>Past Year</MenuItem>
                    <MenuItem value={"allTime"}>All Time</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <ResponsiveContainer width="95%" aspect={3}>
                <LineChart data={artist}>
                  <XAxis dataKey='label' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey='ArtistSignup' stroke="#3D7670" strokeWidth="3px" activeDot={{ r: 5 }} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </div>
        <Box display='flex' style={{ gap: "3rem", marginTop: '50px' }}>
          <div onClick={() => setTabs('album')}>
            <h2 className={tabs === "album" ? classes.underline : " "} style={{ fontSize: "22px", cursor: "pointer" }}>
              Top Album
            </h2>
          </div>
          <div onClick={() => setTabs('song')}>
            <h2 className={tabs === "song" ? classes.underline : " "} style={{ fontSize: "22px", cursor: "pointer" }}>
              Top Songs
            </h2>
          </div>
          <div onClick={() => setTabs('artist')}>
            <h2 className={tabs === "artist" ? classes.underline : " "} style={{ fontSize: "22px", cursor: "pointer" }}>
              Top Artist
            </h2>
          </div>
        </Box>
        <Box>
          {comp}
          {/* {tabs === 'streaming' ? <TopAlbums type={type} /> : <TopArtist type={type} />} */}
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
