import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';
import findFlaggedContentList, {
    findFlaggedContentResponse 
} from 'core/graphql/queries/findFlaggedContentList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from "../pictures/dummyAvatar.svg"
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';


const limit = 10;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        display: 'flex',
    },
    image: {
        width: '55px',
        height: '55px',
        borderRadius: '5px',
    },
    artistContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    acceptBtn: {
        backgroundColor: '#0ED3BF',
        color: "white",
        borderRadius: '4px',
        padding: '8px 15px',
        fontSize: '16px'
    },
    declineBtn: {
        backgroundColor: 'transparent',
        color: "white",
        border: '1px solid white',
        borderRadius: '4px',
        padding: '8px 15px',
        fontSize: '16px'
    },
}));

const FlagList: FC = () => {
    const limit = 10
    const history = useHistory()
    const [page, setPage] = React.useState(parseInt(window.location.search.split("=")[2]));
    const { data, loading, refetch } = useQuery <findFlaggedContentResponse>(findFlaggedContentList,{
        variables: {
            limit: limit,
            offset: limit * page - limit
    }
    });

useEffect(() => {
    refetch();
}, [page]);



let count = data ? Math.floor(data?.findFlagMany?.total / 10) : 1;
const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push({
        search: `?tabs=3&page=${value}`
    })
    setPage(value);
};


const classes = useStyles();

return (
    <>
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                    <TableRow style={{ color: 'white !important' }}>
                        <TableCell width="5%">
                            #
                        </TableCell>
                        <TableCell>
                            Content Title
                        </TableCell>
                        <TableCell>
                            Content Type
                        </TableCell>
                        <TableCell>
                            Reason
                        </TableCell>
                        <TableCell>
                            Additional Comment
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.findFlagMany?.flags !== null ? data?.findFlagMany?.flags.map((val: any, idx: number) => (
                        <TableRow>
                            <TableCell width={"5%"}>
                                {((page - 1) * 10) + ++idx}
                            </TableCell>
                            <TableCell width={"30%"}>
                                <>
                                    <div className={classes.artistContainer}>
                                        {/* {<img src={val.artist?.imageUrl ? val.artist?.imageUrl : Avatar} className={classes.image} />} */}
                                        {val.title}
                                        {/* {val.artist ? val.artist?.displayName : "N.A"} */}
                                    </div>
                                </>
                            </TableCell>
                            <TableCell>
                                {val.contentType}
                            </TableCell>
                            <TableCell>
                                {val.reason}
                            </TableCell>
                            <TableCell>
                                {val.additionalComment ? val.additionalComment : "N.A"}
                            </TableCell>
                        </TableRow>

                    ))
                        : <>
                            <h2 style={{ fontSize: '18px' }}>
                                No Data Found
                            </h2>
                        </>}
                </TableBody>
            </Table>
        </TableContainer>
        <Pagination count={count + 1} page={page} onChange={handleChange} style={{ marginTop: '20px' }} />
    </>
);
};

export default FlagList;
