import gql from 'graphql-tag';

const suspendSong = gql`
  mutation suspendSong($songId: MongoID!) {
    suspendededSong: suspendSong(_id: $songId) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface suspendSongResponse {
  suspendededSong: {
    record: Album;
  };
}

export interface suspendSongVariables {
  songId: string;
}

export default suspendSong;
