import gql from 'graphql-tag';

const SEARCH_BY_TERM = gql`
  query ($term: String) {
    searchResponse: adminSearch(searchTerm: $term) {
      responseType
      id
      name
      plays
      albumId
      image
    }
  }
`;

interface SearchResult {
  responseType: string;
  id: string;
  image: string;
  name: string;
  albumId?: string;
  plays: number;
}

export interface SEARCH_BY_TERMResponse {
  searchResponse: [SearchResult];
}

export interface SEARCH_BY_TERMVariables {
  term: string;
}

export default SEARCH_BY_TERM;
