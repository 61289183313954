import { ApolloClient } from '@apollo/client';
import approveSong, {
  approveSongResponse,
  approveSongVariables,
} from 'core/graphql/mutations/approveSong';
import rejectSong, {
  rejectSongResponse,
  rejectSongVariables,
} from 'core/graphql/mutations/rejectSong';
import suspendSong, {
  suspendSongResponse,
  suspendSongVariables,
} from 'core/graphql/mutations/suspendSong';
import FIND_SONG_BY_ID from 'core/graphql/queries/findSongById';
import { action, observable } from 'mobx';
import disabledSong, { disableSongResponse, disableSongVariables } from 'core/graphql/mutations/disableSong';

class SongActionMenu {
  constructor(private client: ApolloClient<object>, public id: string ) { }

  @observable
  public actionToRun?: () => Promise<void>;

  @action
  public setActionToRun = (action: () => Promise<void>) => {
    this.actionToRun = action;
  };

  public approveSong = async () => {
    await this.client.mutate<approveSongResponse, approveSongVariables>({
      mutation: approveSong,
      variables: {
        songId: this.id,
      },
      refetchQueries: [
        { query: FIND_SONG_BY_ID, variables: { songId: this.id } },
      ],
    });
  };

  public suspendSong = async () => {
    await this.client.mutate<suspendSongResponse, suspendSongVariables>({
      mutation: suspendSong,
      variables: {
        songId: this.id,
      },
      refetchQueries: [
        { query: FIND_SONG_BY_ID, variables: { songId: this.id } },
      ],
    });
  };

  public enableSong = async () => {
    await this.client.mutate<disableSongResponse, disableSongVariables>({
      mutation: disabledSong,
      variables: {
        songId: this.id,
        disabled: false,
      },
      refetchQueries: [
        { query: FIND_SONG_BY_ID, variables: { songId: this.id } },
      ],
    });
  };

  public disableSong = async () => {
    console.log(this.id)
    await this.client.mutate<disableSongResponse, disableSongVariables>({
      mutation: disabledSong,
      variables: {
        songId: this.id,
        disabled: true,
      },
      refetchQueries: [
        { query: FIND_SONG_BY_ID, variables: { songId: this.id } },
      ],
    });
  }

  public rejectSong = async () => {
    await this.client.mutate<rejectSongResponse, rejectSongVariables>({
      mutation: rejectSong,
      variables: {
        songId: this.id,
      },
      refetchQueries: [
        { query: FIND_SONG_BY_ID, variables: { songId: this.id } },
      ],
    });
  };


}

export default SongActionMenu;
