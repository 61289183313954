import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Star from "../pictures/featureStar.svg"
import { Button } from '@mui/material';
import findAllArtist, {
    findAllArtistResponse,
} from "../core/graphql/queries/findAllArtist"
import { Pagination } from '@material-ui/lab';
import Avatar from "../pictures/dummyAvatar.svg"
import TextField from '@mui/material/TextField';
import SearchArtist, {
    searchAllResponse
} from "../core/graphql/queries/searchArtist"
import Sort from "../pictures/sort.svg"
import { useHistory } from 'react-router-dom';
import totalRevenue, {
    newUSerResponse,
    newUSerVariable
} from "../core/graphql/queries/findTotalRevenue"


const EarningListTips = (type: any) => {
    const limit = 10;
    const history = useHistory()

    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState("");
    const [sort, SetSort] = React.useState(false)
    const QueryMultiple = () => {
        const res1 = useQuery<findAllArtistResponse>(findAllArtist,
            {
                variables:
                    { limit: limit, offset: limit * page - limit, type: type.type },
            })

        const res2 = useQuery<searchAllResponse>(
            SearchArtist, {
            variables: {
                text: search,
                type: type.type
            }
        }
        )
        const res3 = useQuery<newUSerResponse>(totalRevenue, {
            variables: {
                type: type.type
            }

        })

        return [res1, res2, res3]
    }

    const [
        { loading: loading1, data: data1, refetch },
        { loading: loading2, data: data2 },
        { loading: loading3, data: data3 },
    ] = QueryMultiple()
    // const { data, loading, refetch } = useQuery

    const [list, SetList] = React.useState(data1 ? data1?.allArtistList?.artist : undefined)
    const [listSearch, SetListSearch] = React.useState(data2 ? data2?.searchArtist : undefined)


    useEffect(() => {
        refetch()
        SetList(data1?.allArtistList?.artist)
        SetListSearch(data2?.searchArtist)
    }, [page, search, type, data1, data2]);

    const handleInput = (event: any) => {
        setSearch(event.target.value)

    };

    const handleSort = (val: any) => {
        SetSort(!sort)
        sort ? SetList(data1?.allArtistList?.artist.slice().sort((a: any, b: any) => b[val] - a[val])) : SetList(data1?.allArtistList?.artist.slice().sort((a: any, b: any) => a[val] - b[val]))
        sort ? SetListSearch(data2?.searchArtist.slice().sort((a: any, b: any) => b.totalEarn - a.totalEarn)) : SetListSearch(data2?.searchArtist.slice().sort((a: any, b: any) => a.totalEarn - b.totalEarn))

    }



    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log(value, 'this is a value')
        setPage(value);
    };

    let count = data1 ? Math.floor(data1?.allArtistList?.total / 10) : 1;

    const useStyles = makeStyles<Theme>((theme) => ({
        container: {
            display: 'flex',
        },
        image: {
            width: '55px',
            height: '55px',
            borderRadius: '5px',
        },
        artistContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
        },
        acceptBtn: {
            backgroundColor: '#0ED3BF',
            color: "white",
            borderRadius: '4px',
            padding: '8px 15px',
            fontSize: '16px'
        },
        declineBtn: {
            backgroundColor: 'transparent',
            color: "white",
            border: '1px solid white',
            borderRadius: '4px',
            padding: '8px 15px',
            fontSize: '16px'
        },
        SearchField: {
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
            margin: "20px 0"
        },
        ClearBtn: {
            background: 'none',
            outline: 'none',
            border: 'none',
            color: "#fff",
            fontWeight: 600,
            display: search ? "block" : "none"

        }

    }));

    const handleClick = (id: any) => {
        history.push(`/artist/${id}`)
    }
    const classes = useStyles();

    return (
        <>
            <div>
                <h2 style={{ fontSize: "24px" }}>
                    ${data3?.totalRevenue.byTip}
                </h2>
            </div>
            <div className={classes.SearchField}>
                <h2 style={{ color: "#3D7670" }}>Search</h2>
                <TextField className={classes.SearchBox} id="outlined-basic" variant="outlined" value={search} onInput={(e) => handleInput(e)} />
                <button className={classes.ClearBtn} onClick={() => {
                    setSearch("")

                }}>
                    Clear
                </button>
            </div>

           
            {!search ? <>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                            <TableRow style={{ color: 'white !important' }}>
                                <TableCell>
                                    #
                                </TableCell>
                                <TableCell>
                                    Title
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex', gap: "0.5rem" }}>
                                        Tips
                                        <img src={Sort} onClick={() => handleSort("tip")} style={{ cursor: "pointer" }} />
                                    </div>

                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.map((val: any, idx: number) => (
                                <TableRow>
                                    <TableCell>
                                        {((page - 1) * 10) + ++idx}
                                    </TableCell>
                                    <TableCell>
                                        <>
                                            <div className={classes.artistContainer}>
                                                <img src={val.image === null ? Avatar : val.image}
                                                    className={classes.image} />
                                                {val.displayName}
                                            </div>

                                        </>
                                    </TableCell>
                                    <TableCell>
                                        {val.tip}
                                    </TableCell>


                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination count={count + 1} page={page} onChange={handleChange} style={{ marginTop: '20px' }} />
            </>
                : <>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow className={classes.thead}>
                                    <TableCell>
                                        #
                                    </TableCell>
                                    <TableCell>
                                        Title
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                                            Tips
                                            <img src={Sort} onClick={() => handleSort("tip")} style={{ cursor: "pointer" }} />
                                        </div>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listSearch && listSearch.map((val: any, idx: number) => (
                                    <TableRow>
                                        <TableCell>
                                            {((page - 1) * 10) + ++idx}
                                        </TableCell>
                                        <TableCell>
                                            <>
                                                <div className={classes.artistContainer}>
                                                    <img src={val.image === null ? Avatar : val.image}
                                                        className={classes.image} />
                                                    {val.displayName}
                                                </div>

                                            </>
                                        </TableCell>
                                        <TableCell>
                                            {val.tip}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>}
        </>
    );
};

export default EarningListTips;
