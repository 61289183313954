import gql from 'graphql-tag';

const FIND_SONG_BY_ID = gql`
  query findSong($songId: MongoID!) {
    song: findSong(filter: { _id: $songId }) {
      name
      plays
      fileLink
      disabled
      _id
      image
      createdAt
      primaryArtist {
        displayName
        image
        _id
      }
      status
      flags {
        _id
        reason
      }
      genre
      costPerPlay
      album {
        title
        _id
        imageUrl
      }
    }
  }
`;

export interface FIND_SONG_BY_IDResponse {
  song: Song;
}

export interface FIND_SONG_BY_IDVariables {
  songId?: string;
}
export default FIND_SONG_BY_ID;
