import React, { FC, useMemo, useState, useEffect, useContext } from 'react';
import SongList from 'components/SongList';
import { Box } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GET_ALBUM_BY_ID, {
  GET_ALBUM_BY_IDResponse,
  GET_ALBUM_BY_IDVariables,
} from 'core/graphql/queries/findAlbumById';
import { makeStyles, Theme } from '@material-ui/core/styles';
import backArrow from '../pictures/backArrow.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ConfirmAction from 'stores/ConfirmAction';
import { useApolloClient } from '@apollo/client';
import AlbumActionMenuManager from 'stores/AlbumActionMenu';
import ConfirmActionPopUp from '../components/@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';
import MoreButton from '../components/@ui/MoreButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { MenuItem, ListItemText } from '@material-ui/core';
import Star from '../pictures/featureStar.svg';
import MusicPlayer from './MusicPlayer';
import usePlayer from './PlayerContext';
import TokenManager from 'core/auth/TokenManager';

interface AlbumProps extends RouteComponentProps<{ id: string }> {}

const AlbumPage: FC<AlbumProps> = ({ match }) => {
  const [showMusic, setShowMusic] = useState<boolean>(false);
  const [musicDetails, setMusicDetails] = useState<any>('');

  const { data, loading } = useQuery<
    GET_ALBUM_BY_IDResponse,
    GET_ALBUM_BY_IDVariables
  >(GET_ALBUM_BY_ID, { variables: { id: match.params.id } });

  const useStyles = makeStyles<Theme>((theme) => ({
    backBtn: {
      position: 'relative',
      top: '3.4%',
      left: '4%',
      display: 'flex',
      gap: '0.3rem',
      alignItems: 'center',
      cursor: 'pointer',
    },
    albumCard: {
      height: '150px',
      width: '150px',
      borderRadius: '2px',
      cursor: 'pointer',
    },
    albumInfo: {
      color: '#3F647A',
      fontSize: '14px',
      lineHeight: '0',
      margin: '0',
    },
    imageCard: {
      width: '50px',
      height: '50px',
    },
    acceptBtn: {
      backgroundColor: '#0ED3BF',
      color: 'white',
      borderRadius: '4px',
      padding: '8px 15px',
      fontSize: '16px',
    },
    declineBtn: {
      backgroundColor: 'transparent',
      color: 'white',
      border: '1px solid white',
      borderRadius: '4px',
      padding: '8px 15px',
      fontSize: '16px',
    },
    artistContainer: {
      display: 'flex',
      gap: '1rem',
      paddingRight: '20px',
    },
    icon: { fill: theme.palette.primary.main },
    featured: {
      color: '#FD4060',
      fontSize: '14px',
    },
  }));

  const classes = useStyles();
  const player: any = usePlayer();
  const manager: any = useContext(TokenManager);
  const token: any = manager.getToken();
  const history = useHistory();

  const hash = useMemo(() => {
    return history.location.hash;
  }, [history.location]);

  const goBack = () => {
    history.goBack();
  };

  let id = window.location.pathname.split('/')[2];

  useEffect(() => {}, [data?.album?._id]);

  const handleSong = (id: string) => {
    history.push(`/song/${id}`);
    player.currentSongOrder.length = 0;
    player.pause();
    player.currentTime = 0;
    player.timeRemaining = player.currentSong?.length;
    player.currentSong = undefined;
  };

  const date = new Date(data?.album?.createdAt ?? ' ')
    .toDateString()
    .split(' ')
    .splice(3, 1)
    .join(' ');
  const client = useApolloClient();

  const [AlbumActionMenuStore] = useState(
    new AlbumActionMenuManager(client, id)
  );
  const [confirmActionStore] = useState(new ConfirmAction());

  const handleApprove = (album: any) => {
    confirmActionStore.setTitle('Approve');
    confirmActionStore.setMessage(
      `Are you sure you want to approve ${album.title}'s  status?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.approveAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleReject = (album: any) => {
    confirmActionStore.setTitle('Reject Approval');
    confirmActionStore.setMessage(
      `Are you sure you want to reject the approval of ${album.title}?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.rejectAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleFeature = (album: any) => {
    confirmActionStore.setTitle('Feature');
    confirmActionStore.setMessage(
      `Are you sure you want to feature the Artist: ${album.title}?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.featureAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleRemoveFeature = (album: any) => {
    confirmActionStore.setTitle('Remove from Featured');
    confirmActionStore.setMessage(
      `Are you sure you want to remove ${album.title} from featured ?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.unfeatureAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleSuspend = (album: any) => {
    confirmActionStore.setTitle('Suspend');
    confirmActionStore.setMessage(
      `Are you sure you want to suspend ${album.title}?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.suspendAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleEnable = (album: any) => {
    confirmActionStore.setTitle('Enable');
    confirmActionStore.setMessage(
      `Are you sure you want to enalbe ${album.title}?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.enableAlbum);
    confirmActionStore.setConfirmOpen(true);
  };
  const handleDisable = (album: any) => {
    confirmActionStore.setTitle('Disable');
    confirmActionStore.setMessage(
      `Are you sure you want to disable ${album.title}?`
    );
    AlbumActionMenuStore.setActionToRun(AlbumActionMenuStore.disableAlbum);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleMusicControl = async (item: any) => {
    const songIds = data?.album?.songs?.map((song: any) => song._id);
    setMusicDetails(item);
    if (token === 'undefined') {
      window.location.href = '/login';
    } else {
      player.selectSong(
        item?._id,
        songIds,
        item?.album?.__id,
        item?.album?.__typename
      );
    }
    setShowMusic(true);
  };

  return (
    <>
      <Box position="relative">
        <div className={classes.backBtn} onClick={goBack}>
          <img src={backArrow} />
          <h2>Back</h2>
        </div>
      </Box>
      <Box padding="20px 12px 0 80px">
        <Box display="flex" justifyContent="space-between">
          <h2 style={{ fontSize: '24px' }}>
            {data?.album?.primaryArtist?.displayName}
          </h2>
          <div>
            {data?.album?.status === 'PENDING' ? (
              <>
                {' '}
                <div className={classes.artistContainer}>
                  <button
                    style={{ cursor: 'pointer' }}
                    className={classes.acceptBtn}
                    onClick={() => {
                      handleApprove(data?.album);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    style={{ cursor: 'pointer' }}
                    className={classes.declineBtn}
                    onClick={() => {
                      handleReject(data?.album);
                    }}
                  >
                    Reject
                  </button>
                </div>
              </>
            ) : (
              ' '
            )}
          </div>
        </Box>
        <Box marginTop="20px" display="flex" style={{ gap: '2rem' }}>
          <div className={classes.albumCard}>
            <img
              src={data?.album?.imageUrl}
              style={{ width: '100%', height: '100%' }}
            />
          </div>

          <div style={{ alignSelf: 'flex-start' }}>
            <h3 style={{ fontSize: '20px' }}>{data?.album?.title}</h3>
            <h3 className={classes.albumInfo}>
              {date} {data?.album?.songs?.length} Songs
            </h3>
            <p className={classes.featured}>
              {data?.album.isFeatured ? (
                <>
                  <span style={{ display: 'flex', gap: '0.3rem' }}>
                    <img src={Star} />
                    Featured Album
                  </span>
                </>
              ) : (
                ''
              )}
            </p>
          </div>
          <div style={{ margin: '20px 0' }}>
            <MoreButton
              dropDownIcon={<MoreHorizIcon className={classes.icon} />}
            >
              {data?.album.isFeatured && data?.album?.status === 'APPROVED' ? (
                <MenuItem onClick={() => handleRemoveFeature(data?.album)}>
                  <ListItemText
                    style={{ color: '#02172A' }}
                    primary="Remove from Featured"
                  />
                </MenuItem>
              ) : (
                data?.album?.status === 'APPROVED' && (
                  <MenuItem onClick={() => handleFeature(data?.album)}>
                    <ListItemText
                      style={{ color: '#02172A' }}
                      primary="Feature"
                    />
                  </MenuItem>
                )
              )}
              {data?.album?.status === 'APPROVED' && (
                <MenuItem onClick={() => handleSuspend(data?.album)}>
                  <ListItemText
                    style={{ color: '#02172A' }}
                    primary="Suspend"
                  />
                </MenuItem>
              )}
              {data?.album?.disabled === true ? (
                <MenuItem onClick={() => handleEnable(data?.album)}>
                  <ListItemText style={{ color: '#02172A' }} primary="Enable" />
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleDisable(data?.album)}>
                  <ListItemText
                    style={{ color: '#02172A' }}
                    primary="Disable"
                  />
                </MenuItem>
              )}
            </MoreButton>
          </div>
        </Box>
        <div style={{ marginTop: '30px' }}>
          <TableContainer style={{ height: 'calc(100vh - 435px)', overflowY: 'auto' }}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow style={{ color: 'white !important' }}>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Prices($)</TableCell>
                  <TableCell>Plays</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.album?.songs &&
                  data?.album?.songs.map((val: any, idx: number) => (
                    <TableRow
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleSong(val._id)}
                    >
                      <TableCell>{++idx}</TableCell>
                      <TableCell
                        onClick={(e) => {
                          handleMusicControl(val);
                          e.stopPropagation();
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                          }}
                        >
                          <img src={val.image} className={classes.imageCard} />
                          {val.name}
                        </div>
                      </TableCell>
                      <TableCell>{val.costPerPlay / 100}</TableCell>
                      <TableCell>{val.plays}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      {showMusic && (
        <MusicPlayer
          setShowMusic={setShowMusic}
          musicDetails={musicDetails}
          allSongsArray={data?.album?.songs}
        />
      )}
      <ConfirmActionPopUp
        context={confirmActionStore}
        actionOnConfirm={AlbumActionMenuStore.actionToRun!}
      />
    </>
  );
};

export default observer(AlbumPage);
