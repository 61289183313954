import gql from "graphql-tag";

const findRevenue = gql`
query($type:String){
    revenueDayWeekMonthYear(type:$type){
      date,
      data
    }
  }
`


export interface findRevenueResponse {
    revenueDayWeekMonthYear:TotalRevenue;
    findUsers: StreamerData;
    findAritst: StreamerData;
    findTopAlbum:StreamerData,
}


export default findRevenue