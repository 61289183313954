import topAlbum, {
    artistSignUpResponse
} from "../core/graphql/queries/findTop5Album"
import { useQuery } from '@apollo/client';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory  } from "react-router-dom";
import { useEffect } from "react";



export const TopAlbums = (type: any) => {
    const useStyles = makeStyles<Theme>((theme) => ({
        imageContainer: {
            width:'100px',
            height:'100px',
            borderRadius:'5px',
        },
        list:{
            display:'flex',
            gap:"0.5rem",
            // justifyContent:"center",
            // alignItems:'center'
        },
        text:{
            fontSize:'14px',
            wordBreak:"break-word"
        }
    }));

    const history = useHistory()

    const classes = useStyles()

    const { data, loading,refetch } = useQuery<artistSignUpResponse>(topAlbum, {
        variables: type
    })

    useEffect(()=>{
        refetch()
    },[type])

    return (
        <>
            <div className={classes.list}>
                {data?.findTopAlbum && data?.findTopAlbum.map((album: any) => (
                    <div style={{width:'120px',textAlign:'center',cursor:'pointer'}} onClick={()=>history.push(`/album/${album._id}`)}>
                        <img src={album.imageUrl} className={classes.imageContainer} />
                        <h3 className={classes.text}>
                            {album.title}
                        </h3>
                    </div>
                ))}
            </div>
        </>
    )
}