import gql from 'graphql-tag';

const findAllUsers = gql`
query($type:String,$limit:Int,$offset:Int){
  allUserList(type:$type, offset:$offset, limit:$limit){
    total,
    users{
      _id,
      firstName,
      lastName,
      totalSpent,
      email

    }

  }
}
`;

export interface findAllUsersResponse {
  allUserList:StreamersSchema;
  searchAllResponse:any
  searchUser:any
  searchArtist:any
}
export interface findAllUsersVariable {
  type:String,
  limit:number,
  offset:number,
}

export default findAllUsers;
