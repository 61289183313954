import gql from "graphql-tag";

const topAlbum = gql`
query($type:String){
    findTopAlbum(type: $type, limit: 5){
      title,
      imageUrl,
      _id
    }
  }`

export interface artistSignUpResponse {
    findUsers: StreamerData;
    findAritst: StreamerData;
    findTopAlbum:Array<Album>,
    findTopArtist:Array<Album>
}
export interface artistSignUpVariable {
    type: String;
}


export default topAlbum; 