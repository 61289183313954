import gql from "graphql-tag";

const totalRevenue = gql`
query($type:String){
 totalRevenue(type:$type){
total,
byTip,
byPlay
}
}


`

export interface newUSerResponse {
    totalRevenue: totalRevenueInterface
    findAlbumMany: Album[];
    findAlbumWithEarning: AllArtistSchema;
    findSongsWithEarning:AllArtistSchema;
    searchAllResponse: any
    searchUser: any
    searchArtist:any
    searchSong:any
    allArtistList: AllArtistSchema;

}
export interface newUSerVariable {
    type: string
}


export default totalRevenue