import topArtists, {
    artistSignUpResponse
} from "../core/graphql/queries/findTop5Artist"
import { useQuery } from '@apollo/client';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";



export const TopArtist = (type: any) => {
    const useStyles = makeStyles<Theme>((theme) => ({
        imageContainer: {
            width: '100px',
            height: '100px',
            borderRadius: '5px',
        },
        list: {
            display: 'flex',
            gap: "0.5rem",
            // justifyContent:"center",
            // alignItems:'cneter'

        },
        text: {
            fontSize: '14px',
            wordBreak: "break-word"
        }
    }));

    const history = useHistory()
    const classes = useStyles()

    const { data, loading,refetch } = useQuery<artistSignUpResponse>(topArtists, {
        variables: type
    })

    console.log(data,'this')

    useEffect(()=>{
        refetch()
    },[type])


    return (
        <>
            <div className={classes.list}>
                
                {data?.findTopSongAlbumArtist && data?.findTopSongAlbumArtist.map((album: any) => (
                    <div style={{width:'120px',textAlign:'center'}} onClick={()=>history.push(`/song/${album._id}`)}>
                        <img src={album.image} className={classes.imageContainer} />
                        <h3 className={classes.text}>
                            {album.name}
                        </h3>
                    </div>
                ))}
            </div>
        </>
    )
}