import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArtistAlbumList from 'components/ArtistAlbumList';
import MusicPageLayout from 'containers/MusicPageLayout';
import FlagCountTracker from 'containers/FlagCountTracker';
import TextLink from 'components/@ui/TextLink';
import ContentFlags from 'containers/ContentFlags';
import { Box } from '@material-ui/core';
import findStreamerById, {
  findStreamerByIdResponse
} from 'core/graphql/queries/findStreamerById';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Avatar from "../pictures/dummyAvatar.svg"
import backArrow from "../pictures/backArrow.svg"


interface StreamerProps extends RouteComponentProps<{ id: string }> { }

const FlagDetailPage: FC<StreamerProps> = ({ match }) => {
  const { data, loading } = useQuery<findStreamerByIdResponse>(
    findStreamerById,
    {
      variables: { customerId: match?.params?.id || '' },
    }
  )

  const useStyles = makeStyles<Theme>((theme) => ({
    backBtn: {
      position: 'relative',
      top: "3.4%",
      left: "4%",
      display: "flex",
      gap: "0.3rem",
      alignItems: "center",
      cursor: 'pointer',
    },
    title: {
      fontSize: "20px"
    }


  }));

  const history = useHistory();

  const goBack = () => {
    history.push("/streamers")
  }


  const classes = useStyles()

  console.log(data?.streamer, 'transaction')

  function Formatdate(dates: any) {
    let date = new Date(dates)
    return date.toLocaleDateString()
  }

  return (
    <>
      <Box position="relative">
        <div className={classes.backBtn} onClick={goBack}>
          <img src={backArrow} />
          <h2>
            Back
          </h2>
        </div>
      </Box>
      <Box padding="50px 12px 0 80px">
        <Box display="flex" alignItems="center" style={{ gap: "1rem" }}>
          <div>
            <img src={Avatar} style={{width:'100px'}} />
          </div>
          <div className={classes.title}>
            <h2>
              {data?.streamer?.firstName} {data?.streamer?.lastName}
            </h2>
          </div>
        </Box>
        <Box marginBottom="30px">
          <div>
            <h2 style={{fontSize:"24px"}}>
              Personal Details
            </h2>
          </div>
          <div style={{ display: 'flex', gap:"3rem"}}>
            <div style={{fontSize:"16px"}}>
              Email : Dummy
            </div>
            <div style={{fontSize:"16px"}}>
              Joining Date : {Formatdate(data?.streamer?.dateOfSignup)}
            </div>
          </div>
        </Box>
        <Box display="flex" style={{gap:"3rem"}}>
          <div>
            <h2 style={{fontSize:"24px"}}>
              Total Spent 
            </h2>
            <p style={{fontSize:"18px"}}>
              {`$ ${data?.streamer?.spentInDollar}`}
            </p>
          </div>
          <div>
            <h2 style={{fontSize:"24px"}}>
              Total Tips
            </h2>
            <p style={{fontSize:"18px"}}>
              {`$ ${data?.streamer?.tipInDollar}`}
            </p>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default FlagDetailPage;
