import gql from 'graphql-tag';

const rejectAlbum = gql`
  mutation rejectAlbum($albumId: String) {
    rejectAlbum(albumId: $albumId) {
      _id
      status
    }
  }
`;

export interface rejectAlbumResponse {
  album: Album;
}

export interface rejectAlbumVariables {
  albumId: string;
}

export default rejectAlbum;
