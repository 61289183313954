import gql from "graphql-tag";

const findAllArtist = gql`
  query($limit:Int,$offset:Int,$type:String){
    allArtistList(type:$type,limit:$limit,offset:$offset){
      artist{
        displayName,
        image,
        _id,
        isFeatured,
        totalEarn,
        tip,
        email
      },
      total
    }
  }
`;

export interface findAllArtistResponse {
  allArtistList: AllArtistSchema
  searchAllResponse:any
  searchUser:any
  searchArtist:any
  totalRevenue: totalRevenueInterface

  // artist:Array<Artist>
}

export default findAllArtist;