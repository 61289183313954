import { observable, action, reaction } from 'mobx';
import axios from 'axios';
import { TokenManagerStore } from 'core/auth/TokenManager';
import PlayerClient from './PlayerClient';
import { setTimeout } from 'timers';
import { clearTimeout } from 'timers';
import generateSongUri, { generateChargeUri } from './generateSongUri';
import { ApolloClient } from '@apollo/client';

export enum PlaylistType {
  ARTIST = 'ARTIST',
  ALBUM = 'ALBUM',
  PLAYLIST = 'PLAYLIST',
  GENRE = 'GENRE',
  LIKED_SONGS = 'LIKED_SONGS',
  RECENTLY_PLAYED = 'RECENTLY_PLAYED',
  SEARCH = 'SEARCH',
}

// enableLogging();

// const player = usePlayer();

class PlayerStore {
  // static mobxLoggerConfig: {
  // 	enabled: false
  // };
  @observable
  public musicPlayer: PlayerClient = new PlayerClient();

  @observable
  public currentSong?: string;

  @observable
  public playingFromPlaylistId?: string;

  @observable
  public playingFromPlaylistType?: PlaylistType;

  @observable
  public originalSongOrder?: Array<string> = [];

  @observable
  public liveHistory?: Array<string> = [];

  @observable
  public duplicateHistoryForPlayOrder?: Array<string> = [];

  @observable
  public playingFromHistory: boolean = false;

  @observable
  public currentSongOrder: Array<string> = [];

  @observable
  public playing = false;

  @observable
  public loading: boolean = false;

  @observable
  public currentTime?: number;

  @observable
  public songDuration?: number;

  @observable
  public timeRemaining?: number;

  public client?: ApolloClient<object>;

  @observable
  public shuffled: boolean = false;

  @observable
  public repeat: boolean = false;

  @observable
  public repeat1: boolean = false;

  @observable
  public autoPlay: boolean = false;

  @observable
  public error?: string;

  @observable
  public volume: number = 1;

  @observable
  public timePlaying: number = 0;

  @action
  public setVolume = async (volume: number) => {
    await this.musicPlayer.setVolume(volume);
    this.volume = volume;
  };

  @action
  public setShuffle = (shuffled: boolean) => {
    this.shuffled = shuffled;

    this.initializeSongOrder();
  };

  @action
  public repeatClick = () => {
    if (this.repeat === false && this.repeat1 === false) {
      this.repeat = true;
      return;
    }

    if (this.repeat === true) {
      this.repeat1 = true;
      this.repeat = false;
      return;
    }

    if (this.repeat1 === true) {
      this.repeat1 = false;
      return;
    }
  };

  @action
  public setAutoPlay = (autoPlay: boolean) => {
    this.autoPlay = autoPlay;
    return;
  };

  @action
  public seek = async (position: number) => {
    await this.musicPlayer.seek(position);
  };

  @action
  public selectSong = (
    id: string,
    relatedSongs?: string[],
    playingFromId?: string,
    playingFromType?: PlaylistType
  ) => {
    this.originalSongOrder = relatedSongs;
    this.playingFromHistory = false;
    this.currentSong = id;
    this.playingFromPlaylistId = playingFromId;

    this.playingFromPlaylistType = playingFromType;

    this.initializeSongOrder();
  };

  @action
  public songPlay = () => {
    this.currentSong = undefined;
  };

  @action
  public initializeSongOrder = () => {
    if (!this.currentSong || !this.originalSongOrder) {
      return;
    }

    let duplicateHistory = this.liveHistory;

    this.duplicateHistoryForPlayOrder = duplicateHistory;

    if (!this.shuffled) {
      this.currentSongOrder = this.originalSongOrder;
      return;
    }

    let shuffledArray = [...this.originalSongOrder].sort(
      () => 0.5 - Math.random()
    );

    const currentSongIndex = shuffledArray.findIndex(
      (s) => s === this.currentSong
    );

    shuffledArray.splice(currentSongIndex, 1);
    shuffledArray.unshift(this.currentSong);

    this.currentSongOrder = shuffledArray;

    return;
  };

  @action
  public next = () => {
    if (!this.currentSong || !this.currentSongOrder) {
      return;
    }
    if (this.repeat1 === true) {
      const songToRepeat = this.currentSong;

      this.currentSong = undefined;

      this.currentSong = songToRepeat;
      return;
    }

    if (this.playingFromHistory === false) {
      const currentSongIndex = this.currentSongOrder.findIndex(
        (s) => s === this.currentSong
      );

      if (currentSongIndex === this.currentSongOrder.length - 1) {
        if (this.repeat) {
          if (this.currentSongOrder.length === 1) {
            const songToRepeat = this.currentSong;

            this.currentSong = undefined;

            this.currentSong = songToRepeat;
            return;
          }

          this.currentSong = this.currentSongOrder[0];
        }

        return;
      }

      this.currentSong = this.currentSongOrder[currentSongIndex + 1];

      return;
    }

    if (this.duplicateHistoryForPlayOrder && this.playingFromHistory === true) {
      const index = this.duplicateHistoryForPlayOrder.findIndex(
        (s) => s === this.currentSong
      );

      if (index === 0) {
        this.playingFromHistory = false;
        this.currentSong = this.currentSongOrder[0];
        return;
      }

      this.currentSong = this.duplicateHistoryForPlayOrder[index - 1];
    }

    return;
  };

  @action
  public previous = () => {
    if (!this.currentSong || !this.currentSongOrder) {
      return;
    }

    if (this.currentTime && this.currentTime >= 5) {
      const songToRepeat = this.currentSong;

      this.currentSong = undefined;

      this.currentSong = songToRepeat;
      return;
    }

    if (this.playingFromHistory && this.duplicateHistoryForPlayOrder) {
      const index = this.duplicateHistoryForPlayOrder.findIndex(
        (s) => s === this.currentSong
      );
      if (index === this.duplicateHistoryForPlayOrder.length - 1) {
        if (this.repeat) {
          this.currentSong =
            this.currentSongOrder[this.currentSongOrder.length - 1];
        }
        return;
      }

      this.currentSong = this.duplicateHistoryForPlayOrder[index + 1];

      return;
    }

    if (!this.playingFromHistory) {
      const currentSongIndex = this.currentSongOrder.findIndex(
        (s) => s === this.currentSong
      );

      if (currentSongIndex === 0 && this.duplicateHistoryForPlayOrder) {
        this.currentSong = this.currentSongOrder[currentSongIndex];
        return;
      }

      if (currentSongIndex !== 0) {
        this.currentSong = this.currentSongOrder[currentSongIndex - 1];
        return;
      }

      if (this.repeat) {
        this.currentSong =
          this.currentSongOrder[this.currentSongOrder.length - 1];
        return;
      }

      if (
        this.duplicateHistoryForPlayOrder &&
        this.duplicateHistoryForPlayOrder.length > 0
      ) {
        this.playingFromHistory = true;
        this.currentSong = this.duplicateHistoryForPlayOrder[0];
        return;
      }

      return;
    }

    return;
  };

  @action
  public setLiveHistory = () => {
    if (!this.currentSong) {
      return;
    }

    if (!this.liveHistory) {
      this.liveHistory = [this.currentSong];
    }

    const historyArray = [...this.liveHistory];

    if (historyArray.length === 100) {
      historyArray.pop();
    }

    const inHistory = historyArray.find((song) => song === this.currentSong);

    if (!inHistory) {
      historyArray.unshift(this.currentSong);

      this.liveHistory = historyArray;
      return;
    }

    const index = historyArray.findIndex((song) => song === this.currentSong);

    historyArray.splice(index, 1);
    historyArray.unshift(this.currentSong);

    this.liveHistory = historyArray;

    return;
  };

  public tokenManager = TokenManagerStore;

  @action
  public updateTimes = (
    currentTime: number,
    timeRemaining: number,
    songDuration: number
  ) => {
    this.currentTime = currentTime;
    this.timeRemaining = timeRemaining;
    this.songDuration = songDuration;
    if (timeRemaining <= 1) {
      this.handleCompleted();
    }
  };

  @action
  public handleCompleted = () => {
    if (this.autoPlay === true) {
      if (this.currentSongOrder.length === 1) {
        this.playing = false;
        this.timePlaying = 0;
      }
      if (
        this.currentSong ===
          this.currentSongOrder[this.currentSongOrder.length - 1] &&
        this.repeat === false
      ) {
        this.playing = false;
        this.timePlaying = 0;
      }
      this.next();
      return;
    }

    this.timePlaying = 0;
    this.playing = false;
  };

  @action
  public relieveError = () => {
    this.error = undefined;
  };

  @action
  public pause = async () => {
    await this.musicPlayer.pause();

    this.playing = false;

    if (this.updateTimePlayingInterval) {
      clearTimeout(this.updateTimePlayingInterval);

      this.updateTimePlayingInterval = undefined;
    }
  };

  @observable
  private updateTimePlayingInterval?: NodeJS.Timeout;

  @action
  public play = async () => {
    if (!this.currentSong) {
      return;
    }

    await this.musicPlayer.play();

    this.playing = true;

    if (!this.updateTimePlayingInterval) {
      this.setPlayingInterval();
    }
  };

  private setPlayingInterval = () => {
    this.updateTimePlayingInterval = setTimeout(() => {
      this.updatePlayingTime();
    }, 1000);
  };

  private updatePlayingTime = () => {
    this.timePlaying += 1000;
    this.setPlayingInterval();
  };

  private load = async (songUri: string) => {
    await this.musicPlayer.load(songUri);
  };

  private unload = async () => {
    await this.musicPlayer.unload();

    this.playing = false;

    this.songDuration = 0;
    this.timeRemaining = 0;
    this.currentTime = 0;
    this.timePlaying = 0;
  };

  private chargeForPlay = async () => {
    // const chargeUri = generateChargeUri(this.currentSong!);
    // const token = await this.tokenManager.getToken();
    // try {
    //   await axios.get(chargeUri, {
    //     headers: {
    //       authorization: token,
    //     },
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  };

  private resetTimePlaying() {
    if (this.updateTimePlayingInterval) {
      clearTimeout(this.updateTimePlayingInterval);

      this.updateTimePlayingInterval = undefined;

      this.timePlaying = 0;
    }
  }

  constructor(client?: ApolloClient<object>) {
    if (client) {
      this.client = client;
    }

    this.musicPlayer.setUpdateTimes(this.updateTimes);

    reaction(
      () => [this.timePlaying],

      async () => {
        // if (this.timePlaying === 10000) {
        // 	await this.chargeForPlay();
        // }
      }
    );

    // play current song
    reaction(
      () => [this.currentSong],

      async () => {
        if (!this.currentSong) {
          return;
        }

        this.resetTimePlaying();

        try {
          this.loading = true;
          this.playing = false;

          await this.unload();

          const songUri = generateSongUri(this.currentSong);

          const token = await this.tokenManager.getToken();

          const res = await axios.get(songUri, {
            headers: {
              authorization: token,
            },
          });

          const url = encodeURI(res.data);

          await this.load(url);

          this.loading = false;

          await this.play();

          this.playing = true;

          this.setLiveHistory();
        } catch (err) {
          const typedError: any = err as Error;
          console.log(typedError.response.data);
          this.error = typedError.response.data;
        }
      }
    );
  }
}

export default PlayerStore;
