import gql from 'graphql-tag';

const disabledSong = gql`
mutation disableSong($songId:String,$disabled:Boolean){ 
    disableSong(songId:$songId,disabled:$disabled){ 
        _id
        disabled
    }
 }
`;

export interface disableSongResponse {
    song: Song;
}

export interface disableSongVariables {
    songId: string;
    disabled: boolean;
}

export default disabledSong;
