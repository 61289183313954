import React from "react";
import ShuffleIcon from "@material-ui/icons/Shuffle";

interface Props {
  shuffle?: boolean;
  small?: boolean;
}

const ShuffleButton: React.FC<Props> = ({ shuffle, small = false }) => {
  if (shuffle === true) {
    return (
      <ShuffleIcon
        style={{ fontSize: small ? "18px" : "2.75vh", fill: "#b74260" }}
      />
    );
  }

  return (
    <ShuffleIcon
      style={{ fontSize: small ? "18px" : "2.75vh", fill: "#ffffff4d" }}
    />
  );
};

export default ShuffleButton;
