import { ApolloClient } from '@apollo/client';
import approveAlbum, {
  approveAlbumResponse,
  approveAlbumVariables,
} from 'core/graphql/mutations/approveAlbum';
import featureAlbum, {
  featureAlbumResponse,
  featureAlbumVariables,
} from 'core/graphql/mutations/featureAlbum';
import rejectAlbum, {
  rejectAlbumResponse,
  rejectAlbumVariables,
} from 'core/graphql/mutations/rejectAlbum';
import suspendAlbum, {
  suspendAlbumResponse,
  suspendAlbumVariables,
} from 'core/graphql/mutations/suspendAlbum';
import unfeatureAlbum, {
  unfeatureAlbumResponse,
  unfeatureAlbumVariables,
} from 'core/graphql/mutations/unfeatureAlbum';
import GET_ALBUM_BY_ID from 'core/graphql/queries/findAlbumById';
import disabledAlbum, {
  disableAlbumResponse, disableAlbumVariables
} from 'core/graphql/mutations/disableAlbum';

import { action, observable } from 'mobx';

class AlbumActionMenu {
  constructor(private client: ApolloClient<object>, public id: string) { }

  @observable
  public actionToRun?: () => Promise<void>;

  @action
  public setActionToRun = (action: () => Promise<void>) => {
    this.actionToRun = action;
  };

  public approveAlbum = async () => {
    await this.client.mutate<approveAlbumResponse, approveAlbumVariables>({
      mutation: approveAlbum,
      variables: {
        albumId: this.id,
      },
      refetchQueries: [{ query: GET_ALBUM_BY_ID, variables: { id: this.id } }],
    });
  };

  public suspendAlbum = async () => {
    await this.client.mutate<suspendAlbumResponse, suspendAlbumVariables>({
      mutation: suspendAlbum,
      variables: {
        albumId: this.id,
      },
      refetchQueries: [{ query: GET_ALBUM_BY_ID, variables: { id: this.id } }],
    });
  };

  public rejectAlbum = async () => {
    await this.client.mutate<rejectAlbumResponse, rejectAlbumVariables>({
      mutation: rejectAlbum,
      variables: {
        albumId: this.id,
      },
      refetchQueries: [{ query: GET_ALBUM_BY_ID, variables: { id: this.id } }],
    });
  };

  public featureAlbum = async () => {
    await this.client.mutate<featureAlbumResponse, featureAlbumVariables>({
      mutation: featureAlbum,
      variables: {
        id: this.id,
      },
      refetchQueries: [{ query: GET_ALBUM_BY_ID, variables: { id: this.id } }],
    });
  };

  public unfeatureAlbum = async () => {
    await this.client.mutate<unfeatureAlbumResponse, unfeatureAlbumVariables>({
      mutation: unfeatureAlbum,
      variables: {
        id: this.id,
      },
      refetchQueries: [{ query: GET_ALBUM_BY_ID, variables: { id: this.id } }],
    });
  };

  public enableAlbum = async () => {
    console.log(this)
    await this.client.mutate<disableAlbumResponse, disableAlbumVariables>({
      mutation: disabledAlbum,
      variables: {
        albumId: this.id,
        disabled: false,
      },
      refetchQueries: [
        { query: GET_ALBUM_BY_ID, variables: { id: this.id } },
      ],
    });
  };
  public disableAlbum = async () => {
    console.log(this.id)
    await this.client.mutate<disableAlbumResponse, disableAlbumVariables>({
      mutation: disabledAlbum,
      variables: {
        albumId: this.id,
        disabled: true,
      },
      refetchQueries: [
        { query: GET_ALBUM_BY_ID, variables: { id: this.id } },
      ],
    });
  }
}
export default AlbumActionMenu;
