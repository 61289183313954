import gql from 'graphql-tag';

const findArtistById = gql`
  query ArtistByID($artistId: MongoID!) {
    artist: findArtist(filter: { _id: $artistId }) {
      _id
      description
      displayName
      bannerImage
      image
      isFeatured
      isActive
      flags {
        _id
      }
      status
      songs {
        costPerPlay
        disabled
        album {
          title
          _id
        }

        name
        plays
        weeklyPlays
        image
        fileLink
        _id
        genre

        primaryArtist {
          displayName
          _id
          image
        }
      }

      albums {
        title
        disabled
        createdAt
        imageUrl
        featuredByArtist
        _id
        isSingle
        primaryArtist {
          displayName
          _id
        }
      }
      user {
        _id
        firstName
        lastName
        isActive
        email
        country
      }
    }
  }
`;

export interface findArtistByIdResponse {
  artist: Artist;
  findArtistTotalEarning:Artist
}

export interface findArtistByIdVariables {
  artistId?: string;
}
export default findArtistById;
