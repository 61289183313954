import Layout from 'components/Layout';
import TokenValidation from 'containers/TokenValidation';
import AlbumPage from 'pages/AlbumPage';
import ArtistPage from 'pages/ArtistPage';
import Dashboard from 'pages/Dashboard';
import ScheduledJobsContainer from 'pages/JobHistoryPage';
import SearchPage from 'pages/SearchPage';
import SongPage from 'pages/SongPage';
import StreamersPage from 'pages/StreamersPage';
import StreamerPage from 'pages/StreamerPage';
import UsersPage from 'pages/UsersPage';
import UserPage from 'pages/UserPage';
import Unauthenticated from 'pages/Unauthenticated';
import PendingApprovalPage from 'pages/PendingApproval';
import UnauthorizedContentMessage from 'pages/UnauthorizedContent';
import FlagDetailPage from 'pages/FlagDetailPage';
import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import EarningPage from 'pages/EarningPage';
import { NotificationPage } from 'pages/NotificationPage';
import PlayerContextProvider from 'pages/PlayerContextContainer';

const routes = {
  unsecure: [
    { path: '/unauthenticated', component: Unauthenticated, exact: true },
    {
      path: '/unauthorized',
      component: UnauthorizedContentMessage,
      exact: true,
    },
  ],
  secure: [
    { path: '/', component: Dashboard, exact: true },
    { path: '/streamers', component: StreamersPage, exact: true },
    { path: '/artist', component: UsersPage, exact: true },
    // { path: '/artist/:id', component: UserPage, exact: true },
    { path: '/streamer/:id', component: StreamerPage, exact: true },
    { path: '/artist/:id', component: ArtistPage, exact: true },
    { path: '/artists/:id', component: ArtistPage, exact: true },
    { path: '/album/:id', component: AlbumPage, exact: true },
    { path: '/song/:id', component: SongPage, exact: true },
    { path: '/job-history', component: SongPage, exact: true },
    { path: '/jobs', component: ScheduledJobsContainer, exact: true },
    { path: '/search', component: SearchPage, exact: true },
    { path: '/approval', component: PendingApprovalPage, exact: true },
    { path: '/earning', component: EarningPage, exact: true },
    { path: '/flags', component: FlagDetailPage, exact: true },
    // { path: '/notifications', component: NotificationPage, exact: true },
  ],
};

interface IRootProps {}

const Root: FC<IRootProps> = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.unsecure.map(({ path, component, exact }) => (
          <Route key={path} path={path} component={component} exact={exact} />
        ))}
        <TokenValidation>
          <PlayerContextProvider>
            <Layout>
              {routes.secure.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  component={component}
                  exact={exact}
                />
              ))}
            </Layout>
          </PlayerContextProvider>
        </TokenValidation>
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
