import AlbumApprovalList from 'components/AlbumApprovalList';
import ArtistApprovalList from 'components/ArtistApprovalList';
import React, { FC, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FlagList from 'components/FlagList';
import { useHistory } from 'react-router-dom';
import BellIcon from "../pictures/belly.svg"


const PendingApprovalPage: FC = () => {

    const [tabs, setTabs] = useState(parseInt(window.location.search.split("=")[1]))
    const useStyles = makeStyles<Theme>((theme) => ({
        tabContainer: {
            display: "flex",
            gap: "3rem",
            fontSize: "20px",
            marginTop: '30px',
        },
        header: {
            fontSize: "24px",
        },
        underline: {
            borderBottom: "2px solid #0ED3BF",
            paddingBottom: "1px"
        },
        mainDiv: {
            padding: '20px 50px',
        }
    }));

    console.log(parseInt(window.location.search.split("=")[1]))
    let comp
    const classes = useStyles()

    if (tabs === 1) {
        comp = <ArtistApprovalList />
    }
    else if (tabs === 2) {
        comp = <AlbumApprovalList />
    }
    else {
        comp = <FlagList />
    }

    const history = useHistory()


  const handleNotification = () => {
    history.push("/notifications")
  }

    return (
        <>
            <div className={classes.mainDiv}>
                <div style={{ display: 'flex', justifyContent: 'space-between'  }}>
                    <h1 className={classes.header}>
                        Approvals
                    </h1>
                    {/* <div style={{ cursor: 'pointer', textAlign: 'end', marginBottom: '20px' }} onClick={handleNotification}>
                        <img src={BellIcon} />
                    </div> */}
                </div>

                <div className={classes.tabContainer}>
                    <div>
                        <h3 style={{ cursor: "pointer" }} onClick={() => {
                            setTabs(1)
                            history.push({
                                search: "?tabs=1"
                            })
                        }} className={tabs === 1 ? classes.underline : ''}>Artist</h3>
                    </div>
                    <div>
                        <h3 onClick={() => {
                            setTabs(2)
                            history.push({
                                search: "?tabs=2"
                            })
                        }} style={{ cursor: "pointer" }} className={tabs === 2 ? classes.underline : ''}>
                            Album
                        </h3>
                    </div>

                    <div>
                        <h3 onClick={() => {
                            setTabs(3)
                            history.push({
                                search: "?tabs=3&page=1"
                            })
                        }
                        } style={{ cursor: "pointer" }} className={tabs === 3 ? classes.underline : ''}>
                            Flagged
                        </h3>
                    </div>
                </div>
                <div>
                    {comp}
                </div>

            </div>
        </>
    )
}


export default PendingApprovalPage