import React, { useContext } from 'react';
import { Alert } from '@material-ui/lab';
import EventMessageContext from 'stores/EventMessage';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IStyleProps {
  successType?: string;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    width: '100%',
    backgroundColor: (props) =>
      props.successType === 'success' ? '#3D7670' : '#DE1B3C',
    '& .MuiAlert-message': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& .MuiAlert-icon': {
      color: 'white',
    },
    '& .MuiAlert-action': {
      color: 'white',
    },
  },
  message: {
    color: '#fff',
    fontWeight: 500,
  },
  snackbar: {
    minWidth: '20%',
  },
}));

const DescriptionAlert = () => {
  const eventManager = useContext(EventMessageContext);
  const classes = useStyles({ successType: eventManager.severityType });

  return (
    <div className={classes.root}>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={eventManager.message ? true : false}
      >
        <Alert
          onClose={
            eventManager.severityType === 'error'
              ? eventManager.clearEvent
              : undefined
          }
          className={classes.alert}
          severity={
            eventManager.severityType as
              | 'success'
              | 'error'
              | 'info'
              | 'warning'
              | undefined
          }
        >
          <Typography className={classes.message}>
            {eventManager.message}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(DescriptionAlert);
