import React, { FC, useMemo, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import findArtistById, {
  findArtistByIdResponse,
  findArtistByIdVariables,
} from 'core/graphql/queries/findArtistById';
import findArtistEarning, {
  findArtistDetailsResponse,
  findArtistDetailsVariables,
} from 'core/graphql/queries/findArtistTotalEarning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Star from '../pictures/featureStar.svg';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import ArtistAlbumList from 'components/ArtistAlbumList';
import MusicPageLayout from 'containers/MusicPageLayout';
import FlagCountTracker from 'containers/FlagCountTracker';
import TextLink from 'components/@ui/TextLink';
import ContentFlags from 'containers/ContentFlags';
import { Box } from '@material-ui/core';
import ArtistActionMenu from '../components/ArtistActionMenu';
import backArrow from '../pictures/backArrow.svg';
import ConfirmAction from 'stores/ConfirmAction';
import { useApolloClient } from '@apollo/client';
import ArtistActionMenuManager from 'stores/ArtistActionMenu';
import ConfirmActionPopUp from '../components/@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';
import Avatar from '../pictures/dummyAvatar.svg';
import MoreButton from '../components/@ui/MoreButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { MenuItem, ListItemText } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MusicPlayer from './MusicPlayer';
import usePlayer from './PlayerContext';
import TokenManager from 'core/auth/TokenManager';
// import {countries} from 'country-data';
import GET_ALBUM_BY_ID, {
  GET_ALBUM_BY_IDResponse,
  GET_ALBUM_BY_IDVariables,
} from 'core/graphql/queries/findAlbumById';
import { countries } from 'country-data';

interface ArtistProps extends RouteComponentProps<{ id: string }> {}

const ArtistPage: FC<ArtistProps> = ({ match }) => {
  const location: any = useLocation();
  const [musicDetails, setMusicDetails] = useState<any>('');
  console.log('match.params.id', match.params.id);
  console.log('location', location?.pathname?.split('/')[1]);

  const QueryMultiple = () => {
    const res1 = useQuery<any>(findArtistById, {
      variables: { artistId: match.params.id },
    });
    const res2 = useQuery<any>(findArtistEarning, {
      variables: { id: match.params.id },
    });
    return [res1, res2];
  };

  const [showMusic, setShowMusic] = useState<boolean>(false);

  const [
    { loading: loading1, data: data1 },
    { loading: loading2, data: data2 },
  ] = QueryMultiple();

  const history = useHistory();

  const useStyles = makeStyles<Theme>((theme) => ({
    banner: {
      backgroundPosition: 'center',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '60px',
      position: 'relative',
    },
    bannerImage: {
      width: '100%',
    },
    avatar: {
      width: '100px',
      height: '100px',
      position: 'relative',
      // top: '-15px',
      borderRadius: '50%',
    },
    artistName: {
      fontSize: '24px',
      margin: '0',
    },
    featured: {
      color: '#FD4060',
      fontSize: '14px',
    },
    description: {
      fontSize: '14px',
      fontWeight: 300,
      lineHeight: '30px',
    },
    albumCard: {
      height: '150px',
      width: '150px',
      borderRadius: '2px',
      cursor: 'pointer',
    },
    backBtn: {
      position: 'absolute',
      top: '3.4%',
      left: '4%',
      display: 'flex',
      gap: '0.3rem',
      alignItems: 'center',
      cursor: 'pointer',
    },
    acceptBtn: {
      backgroundColor: '#0ED3BF',
      color: 'white',
      borderRadius: '4px',
      padding: '8px 15px',
      fontSize: '16px',
    },
    declineBtn: {
      backgroundColor: 'transparent',
      color: 'white',
      border: '1px solid white',
      borderRadius: '4px',
      padding: '8px 15px',
      fontSize: '16px',
    },
    artistContainer: {
      display: 'flex',
      gap: '1rem',
      paddingRight: '20px',
    },
    icon: { fill: theme.palette.primary.main },
    container: {
      display: 'flex',
    },
    image: {
      width: '55px',
      height: '55px',
      borderRadius: '5px',
    },
    thead: {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
    rows: {
      'nth-of-type(even)': {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
      },
    },
    SearchField: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.3rem',
      marginBottom: '20px',
    },
    ClearBtn: {
      background: 'none',
      outline: 'none',
      border: 'none',
      color: '#fff',
      fontWeight: 600,
      // display: search ? 'block' : 'none',
    },
  }));

  const classes = useStyles();
  const client = useApolloClient();
  const player: any = usePlayer();
  const manager: any = useContext(TokenManager);
  const token = manager.getToken();

  let id = window.location.pathname.split('/')[2];

  const [ArtistActionMenuStore] = useState(
    new ArtistActionMenuManager(client, id, '')
  );

  const [confirmActionStore] = useState(new ConfirmAction());

  const handleClick = (id: any) => {
    history.push(`/album/${id}`);
  };

  const goBack = () => {
    history.goBack();
  };
  const handleApprove = (artist: any) => {
    confirmActionStore.setConfirmOpen(true);
    confirmActionStore.setTitle('Approve');
    confirmActionStore.setMessage(
      `Are you sure you want to approve ${artist.displayName}'s  status?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.approveArtist);
    // setShowMusic(false);
    // player.pause();
  };

  const handleReject = (artist: any) => {
    confirmActionStore.setTitle('Reject Approval');
    confirmActionStore.setMessage(
      `Are you sure you want to reject the approval of ${artist.displayName}?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.rejectArtist);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleFeature = (artist: any) => {
    confirmActionStore.setTitle('Feature');
    confirmActionStore.setMessage(
      `Are you sure you want to feature the Artist: ${artist.displayName}?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.featureArtist);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleRemoveFeature = (artist: any) => {
    confirmActionStore.setTitle('Remove from Featured');
    confirmActionStore.setMessage(
      `Are you sure you want to remove ${artist.displayName} from featured ?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.unfeatureArtist);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleSuspend = (artist: any) => {
    confirmActionStore.setTitle('Suspend');
    confirmActionStore.setMessage(
      `Are you sure you want to suspend ${artist.displayName}?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.suspendArtist);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleEnable = (artist: any) => {
    confirmActionStore.setTitle('Enable');
    confirmActionStore.setMessage(
      `Are you sure you want to enalbe ${artist.displayName}?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.enableArtist);
    confirmActionStore.setConfirmOpen(true);
  };
  const handleDisable = (artist: any) => {
    confirmActionStore.setTitle('Disable');
    confirmActionStore.setMessage(
      `Are you sure you want to disable ${artist.displayName}?`
    );
    ArtistActionMenuStore.setActionToRun(ArtistActionMenuStore.disableArtist);
    confirmActionStore.setConfirmOpen(true);
  };

  const handleMusicControl = async (item: any) => {
    const songIds = data1?.artist?.songs?.map((song: any) => song._id);
    setMusicDetails(item);
    if (token === 'undefined') {
      window.location.href = '/login';
    } else {
      player.selectSong(
        item?._id,
        songIds,
        item?.album?.__id,
        item?.album?.__typename
      );
    }
    setShowMusic(true);
  };

  const { data } = useQuery<GET_ALBUM_BY_IDResponse, GET_ALBUM_BY_IDVariables>(
    GET_ALBUM_BY_ID,
    { variables: { id: match.params.id } }
  );

  let countryName: any = countries[data1?.artist?.user?.country]?.name;

  return (
    <>
      <Box className={classes.banner}>
        <div className={classes.backBtn} onClick={goBack}>
          <img alt="backArrowImage" src={backArrow} />
          <h2 onClick={() => player.pause()}>Back</h2>
        </div>
      </Box>
      <Box padding="20px 50px">
        <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            width="100%"
            style={{ gap: '10px' }}
          >
            <div className={classes.avatar}>
              <img
                alt="imagealt"
                src={
                  data1?.artist?.image === null ? Avatar : data1?.artist?.image
                }
                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
              />
            </div>
            <h2 className={classes.artistName}>{data1?.artist.displayName}</h2>
          </Box>
          {data1?.artist?.status === 'PENDING' ? (
            <>
              {' '}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                {data1?.artist?.status === 'PENDING' ? (
                  <>
                    {' '}
                    <div className={classes.artistContainer}>
                      <h2> Status - Pending </h2>
                      <button
                        style={{ cursor: 'pointer' }}
                        className={classes.acceptBtn}
                        onClick={() => {
                          handleApprove(data1?.artist);
                        }}
                      >
                        Approve
                      </button>
                      <button
                        style={{ cursor: 'pointer' }}
                        className={classes.declineBtn}
                        onClick={() => {
                          handleReject(data1?.artist);
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </>
                ) : (
                  ' '
                )}
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <div>
                <h2 className={classes.artistName}>
                  {data1?.artist?.displayName}
                </h2>

                <p className={classes.featured}>
                  {data1?.artist?.isFeatured ? (
                    <>
                      <span style={{ display: 'flex', gap: '0.3rem' }}>
                        <img alt="starImage" src={Star} />
                        Featured Artist
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </p>
              </div>
              <div>
                <MoreButton
                  dropDownIcon={<MoreHorizIcon className={classes.icon} />}
                >
                  {data1?.artist?.isFeatured &&
                  data1?.artist?.status === 'APPROVED' ? (
                    <MenuItem
                      onClick={() => handleRemoveFeature(data1?.artist)}
                    >
                      <ListItemText
                        style={{ color: '#02172A' }}
                        primary="Remove from Featured"
                      />
                    </MenuItem>
                  ) : (
                    data1?.artist?.status === 'APPROVED' && (
                      <MenuItem onClick={() => handleFeature(data1?.artist)}>
                        <ListItemText
                          style={{ color: '#02172A' }}
                          primary="Feature"
                        />
                      </MenuItem>
                    )
                  )}
                  {data1?.artist?.status === 'APPROVED' && (
                    <MenuItem onClick={() => handleSuspend(data1?.artist)}>
                      <ListItemText
                        style={{ color: '#02172A' }}
                        primary="Suspend"
                      />
                    </MenuItem>
                  )}
                  {data1?.artist?.isActive === false ? (
                    <MenuItem onClick={() => handleEnable(data1?.artist)}>
                      <ListItemText
                        style={{ color: '#02172A' }}
                        primary="Enable"
                      />
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={() => handleDisable(data1?.artist)}>
                      <ListItemText
                        style={{ color: '#02172A' }}
                        primary="Disable"
                      />
                    </MenuItem>
                  )}
                </MoreButton>
              </div>
            </div>
          )}
        </Box>
        {data1?.artist?.status === 'PENDING' ? (
          ''
        ) : (
          <>
            <div className={classes.description}>
              {data1?.artist?.description}
            </div>
            <div style={{ margin: '50px 0' }}>
              <h2 style={{ fontSize: '24px', lineHeight: '10px' }}>
                Artist's Earnings :
              </h2>
              <p style={{ fontSize: '18px' }}>
                $ {data2?.findArtistTotalEarning?.totalEarnInDollar}
              </p>
            </div>
            {location?.pathname?.split('/')[1] === 'artist' ? (
              <Box
                style={{ marginTop: '-40px' }}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ gap: '10px' }}
                >
                  <h2>First Name : {data1?.artist?.user?.firstName} </h2>
                  <h2>Last Name : {data1?.artist?.user?.lastName} </h2>
                </Box>
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ gap: '10px' }}
                >
                  <h2>Email : {data1?.artist?.user?.email} </h2>
                  <h2>Country : {countryName} </h2>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            <Box>
              <h2 style={{ fontSize: '24px' }}>
                Albums
                <span
                  style={{
                    marginLeft: '10px',
                    fontSize: '12px',
                    fontWeight: 300,
                  }}
                >
                  {data1?.artist?.albums?.length} albums
                </span>
              </h2>
              {data1?.artist?.albums &&
                data1?.artist?.albums?.map((album: any) => (
                  <>
                    <div style={{ display: 'inline-flex', margin: '0 10px' }}>
                      <div onClick={() => handleClick(album?._id)}>
                        <img
                          alt="albumCardImage"
                          src={album?.imageUrl}
                          className={classes.albumCard}
                        />
                        <p style={{ fontSize: '14px' }}>{album?.title}</p>
                      </div>
                    </div>
                  </>
                ))}
            </Box>
          </>
        )}
      </Box>
      {location?.pathname?.split('/')[1] === 'artist' ? (
        <></>
      ) : (
        <Box
          padding="20px 50px"
          display="flex"
          flexDirection="column"
          style={{ gap: '10px' }}
        >
          <Box
            style={{ marginTop: '-40px' }}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              style={{ gap: '10px' }}
            >
              <h2>First Name : {data1?.artist?.user?.firstName} </h2>
              <h2>Last Name : {data1?.artist?.user?.lastName} </h2>
            </Box>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              style={{ gap: '10px' }}
            >
              <h2>Email : {data1?.artist?.user?.email} </h2>
              <h2>Country : {countryName} </h2>
            </Box>
          </Box>

          <Box>
            <h2>Album Tracks</h2>
            <TableContainer style={{ height: 'calc(100vh - 415px)', overflow: 'auto' }}>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow className={classes.thead}>
                    <TableCell width="5%" style={{ color: 'white' }}>
                      #
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>Name</TableCell>
                    <TableCell style={{ color: 'white' }}>Genre</TableCell>
                    <TableCell style={{ color: 'white' }}>
                      StarFarm ID
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>Plays</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data1?.artist?.songs?.map((item: any, index: number) => {
                    return (
                      <TableRow
                        className={classes.rows}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleMusicControl(item)}
                      >
                        <TableCell style={{ color: 'white' }}>
                          {index + 1}
                        </TableCell>
                        <TableCell width={'20%'} style={{ color: 'white' }}>
                          {item?.name}
                        </TableCell>
                        <TableCell width={'30%'} style={{ color: 'white' }}>
                          {item?.genre}
                        </TableCell>
                        <TableCell width={'30%'} style={{ color: 'white' }}>
                          {item?.album?._id}
                        </TableCell>
                        <TableCell className="colorBlue">
                          {item?.plays}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
      {showMusic && (
        <MusicPlayer
          setShowMusic={setShowMusic}
          musicDetails={musicDetails}
          allSongsArray={data1?.artist?.songs}
        />
      )}
      <ConfirmActionPopUp
        context={confirmActionStore}
        actionOnConfirm={ArtistActionMenuStore.actionToRun!}
      />
    </>
  );
};

export default observer(ArtistPage);
