import gql from "graphql-tag";

const artistSignUp = gql`
query($type:String){
findAritst:artistsSignupDayWeekMonthYear(type:$type){
    dates,
    data
  }
  }`

export interface artistSignUpResponse {
    findUsers: StreamerData;
    findAritst: StreamerData;
    findTopAlbum:StreamerData,
    findTopArtist:StreamerData,
    revenueDayWeekMonthYear:TotalRevenue
}
export interface artistSignUpVariable {
    type: String;
}


export default artistSignUp; 