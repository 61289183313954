import gql from "graphql-tag";

const SearchUSer = gql`
query($type:String,$text:String){
    searchUser(type:$type,searchText:$text){
      firstName,
      lastName,
      _id,
      totalSpent
    }
  }
`

export interface searchAllResponse {
    searchUser: any
    allArtistList: AllArtistSchema;
    allUserList: StreamersSchema;
    findAlbumMany: Album[];
    findAlbumWithEarning: AllArtistSchema;
    findSongsWithEarning: AllArtistSchema
    searchArtist: any
    searchSong: any

}

export interface searchAllVariable {
    type: String,
    text: String
}

export default SearchUSer