import gql from 'graphql-tag';

const disabledArtist = gql`
  mutation disableArtist($artistId: String, $isActive: Boolean) {
    disableArtist(artistId: $artistId, isActive: $isActive) {
      _id
      isActive
    }
  }
`;

export interface disableArtistResponse {
  artist: Artist;
}

export interface disableArtistVariables {
  artistId: string;
  isActive: boolean;
}

export default disabledArtist;
