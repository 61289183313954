import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import findPendingApprovalAlbum, {
    findPendingApprovalResponse,
} from 'core/graphql/queries/findPendingApprovalAlbum';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Star from "../pictures/featureStar.svg"
import { useHistory } from 'react-router';
import DummyAlbum from "../pictures/dummyRecord.svg"

const limit = 10;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        display: 'flex',
    },
    image: {
        width: '55px',
        height: '55px',
        borderRadius: '5px',
    },
    artistContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    viewText: {
        color: "#FD4060",
        display: "block",
    },
    rows: {
        color: 'white',
        '&:hover ': {
            color: "#FD4060",
            cursor: "pointer",
        }
    },

}));

const ArtistApprovalList: FC = () => {
    const [page, setPage] = useState(1);
    const [hover, setHover] = useState(false)
    const { data, loading, refetch } = useQuery(findPendingApprovalAlbum);

    useEffect(() => {
        refetch();
    }, [page]);

    const history = useHistory()

    console.log(data?.findPendingApproval?.data, 'here')

    const classes = useStyles();

    const handleIn = () => {
        console.log('hi')
        setHover(true)
    }
    const handleOut = () => {
        console.log('bye')
        setHover(false)
    }



    const handleView = (id: any) => {
        history.push(`/album/${id}`)
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableBody>
                        {data?.findPendingApproval?.data.length ? data?.findPendingApproval?.data.map((val: any, idx: number) => (
                            <TableRow key={idx} onClick={() => handleView(val?.album?._id)} style={{ cursor: 'pointer' }}>
                                <TableCell width="5%">
                                    {++idx}
                                </TableCell>
                                <TableCell>
                                    <>
                                        <div className={classes.artistContainer}>
                                            {<img src={val.album?.imageUrl === 'REPLACE_ME' ? DummyAlbum : val.album?.imageUrl} className={classes.image} />}
                                            {val.album ? val.album?.title : "N.A"}
                                        </div>
                                    </>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <>
                                        <div title="views" className={classes.rows}>
                                            View
                                        </div>
                                    </>
                                </TableCell>
                                <TableCell className='colorBlue'>
                                    {/* {val._id} */}
                                </TableCell>
                            </TableRow>
                        )) : <>
                            <h2 style={{ fontSize: '18px' }}>
                                No Data Found
                            </h2>
                        </>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ArtistApprovalList;
