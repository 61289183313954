import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import ConfirmAction from 'stores/ConfirmAction';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    display: 'flex',
    minWidth: '350px',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F5F7F8',
    color: '#fff',
    padding: '25px 10px',
    backgroundColor:'#192B3B'
  },
  actions: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  button: { color: theme.palette.primary.main, padding: '10px 50px' },
  text: {
    color: "white",
    maxWidth: '50ch',
    textAlign: 'center',
    marginBottom: '25px',
    wordBreak: 'break-word',
  },
  title: {
    color: "white",
  },
}));

interface IConfirmActionPopUpProps {
  context: ConfirmAction;
  actionOnConfirm: () => Promise<void>;
}

const ConfirmActionPopUp: FC<IConfirmActionPopUpProps> = ({
  actionOnConfirm,
  context,
}) => {
  const classes = useStyles();

  const handleConfirm = async () => {
    context.setConfirmOpen(false);
    await actionOnConfirm();
  };

  return (
    <Dialog
      hideBackdrop
      open={context.confirmOpen}
      onClose={() => context.setConfirmOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.contentContainer}>
        <DialogTitle className={classes.title}>
          <Typography variant="h6">{context.title}</Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            className={classes.text}
            id="alert-dialog-description"
          >
            {context.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleConfirm} className={classes.button} style={{color:'#0ED3BF!important'}}>
            Confirm
          </Button>
          <Button
            onClick={() => context.setConfirmOpen(false)}
            className={classes.button}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default observer(ConfirmActionPopUp);
