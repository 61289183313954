import gql from 'graphql-tag';

const GET_ALBUM_BY_ID = gql`
    query albumById($id: MongoID!) {
      album: findAlbumById(_id: $id) {
        _id
        title
        createdAt
        description
        status
        genre
        releaseDate
        flags {
          _id
        }
        isFeatured
        disabled
        primaryArtist {
          _id
          displayName
          image
        }

        songs {
          disabled
          plays
          flags {
            _id
          }
          primaryArtist {
            displayName
            _id
            image
          }
          image
          album {
            title
            _id
          }
          _id
          name
          fileLink
          costPerPlay
        }

        imageUrl
        isSingle
      }
    }
`;

export interface GET_ALBUM_BY_IDResponse {
  album: Album;
}

export interface GET_ALBUM_BY_IDVariables {
  id: string;
}

export default GET_ALBUM_BY_ID;
