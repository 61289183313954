import gql from 'graphql-tag';

const findFlaggedContentList = gql`
query($limit:Int,$offset:Int){
  findFlagMany(limit:$limit,offset:$offset){
      total,
      flags{
      contentId,
      contentType,
      additionalComment,
      title,
      reason,
      resolved
      }
    }
  }
`;

export interface findFlaggedContentResponse {
  findFlagMany:any,
  flaggedContent: [
    {
      contentType: string;
      album?: Album;
      artist?: Artist;
      song?: Song;
    }
  ];
}
export interface findFlaggedContentVariables {
 limit:number,
 offset:number
}

export default findFlaggedContentList;
