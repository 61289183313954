import gql from 'graphql-tag';

const findPendingApprovalAlbum = gql`
query{
  findPendingApproval(type:"album"){
    data{
      approvalType,
      album{
        title,
        primaryArtist{
          displayName
        },
        imageUrl,
        _id
      }
      }
      }
  }
`;

interface response {
  data: [
    {
      approvalType: string;
      album?: Album;
      artist?: Artist;
    }
  ];
}

export interface findPendingApprovalResponse {
  data: response;
}

export default findPendingApprovalAlbum;
