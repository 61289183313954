import gql from 'graphql-tag';

const approveArtist = gql`
  mutation approveArtist($artistId: String) {
    approveArtist(artistId: $artistId) {
      _id
      status
    }
  }
`;

export interface approveArtistResponse {
  artist: Artist;
}

export interface approveArtistVariables {
  artistId: string;
}

export default approveArtist;
