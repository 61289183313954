/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden/Hidden';
import Typography from '@material-ui/core/Typography/Typography';
import IconButton from '@material-ui/core/IconButton/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid/Grid';
import { Box } from '@material-ui/core';
import PlayButton from './PlayButton';
import ModalControls from './ModalControls';
import MusicPlayerSlider from './MusicPlayerSlider';
import VolumeControls from './VolumeControls';
import usePlayer from './PlayerContext';
import { CircularProgress } from '@mui/material';
import GET_SONG_BY_ID, {
  GET_SONG_BY_IDResponse,
  GET_SONG_BY_IDVariables,
} from 'core/graphql/queries/getSongById';

const useStyles = (image?: string) =>
  makeStyles((theme) =>
    createStyles({
      controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
        },
      },
      musicplayerContainer: {
        display: 'flex',
        // marginTop:'10px',
        justifyContent: 'space-between',
        padding: '10px 20px 10px 10px',
        alignItems: 'center',
      },
      closeModal: {
        height: '10%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-center',
      },
      focusedSongModal: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        textAlign: 'center',
      },
      headings: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        minWidth: '250px',
        marginRight: '50px',
        [theme.breakpoints.down('xs')]: {
          width: '70%',
          minWidth: '70%',
          marginRight: '0px',
        },
      },
      musicPlayerWidth: { width: '100%', minWidth: '80px' },
      imageStyle: {
        backgroundImage: `url('${image}')`,
        backgroundSize: 'cover',
        minHeight: 50,
        minWidth: 50,
      },
      textStyle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      textContainer: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
    })
  );

interface IMusicPlayerProps {}

const MusicPlayer: FC<any> = ({ setShowMusic }: any) => {
  const player: any = usePlayer();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [refreshName, setRefreshName] = useState(false);
  // const { getSong } = useQuery<GET_SONG_BY_IDResponse, GET_SONG_BY_IDVariables>(
  //   GET_SONG_BY_ID
  // );

  const { data, loading } = useQuery<GET_SONG_BY_IDResponse>(GET_SONG_BY_ID, {
    variables: {
      songId: player.currentSong,
    },
  });

  console.log("data",data);
  

  const classes = useStyles()();

  // const handleGetSong = async () => {
  //   debugger;
  //   try {
  //     console.log('run');

  //     const resp = await getSong({
  //       variables: {
  //         songId: player.currentSong,
  //       },
  //     });

  //     console.log('runATLast');
  //   } catch (e) {}
  // };

  // useEffect(() => {
  //   console.log('inside new use Effect');

  //   async function fetchData() {
  //     const resp = await getSong({
  //       variables: {
  //         songId: player.currentSong,
  //       },
  //     });

  //     console.log('NewUseEfect', resp);
  //   }
  //   console.log('downside new use Effect');

  //   fetchData();
  // }, [player?.currentSong, getSong]);

  useEffect(() => {
    // handleGetSong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player?.currentSong, refreshName]);

  useEffect(() => {
    if (player?.error) {
      setDialogOpen(true);
    }
  }, [player?.error]);

  const handleDetailToggle = () => {
    if (open === true) {
      setOpen(!open);
    }

    if (window.innerWidth < 959.95) {
      setOpen(!open);
    }
  };

  const playButtonClick = () => {
    if (!player?.playing) {
      player?.play();
      return;
    }
    player?.pause();
  };

  const handleClose = () => {
    player.currentSongOrder.length = 0;
    player.pause();
    player.currentTime = 0;
    player.timeRemaining = player.currentSong?.length;
    player.currentSong = undefined;
  };

  // let data:any=[];

  return (
    <>
      {data?.song?.name === undefined ? (
        <CircularProgress
          size={30}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        />
      ) : (
        <Box className="musicPlayerResponsive" onClick={handleDetailToggle}>
          <Box className={classes.musicplayerContainer} pr={3}>
            <Box className={classes.headings}>
              <Box className={classes.imageStyle}>
                <img
                  src={data?.song?.image}
                  alt="https://imgur.com/pjosLlA"
                  height={50}
                  width={50}
                />
              </Box>
              <Box className={classes.textContainer} ml={1.25}>
                <Typography noWrap variant="h5" className={classes.textStyle}>
                  {data?.song?.name}
                </Typography>

                <Typography
                  noWrap
                  className={classes.textStyle}
                  variant="body2"
                  color="textSecondary"
                >
                  {data?.song?.primaryArtist?.displayName}
                </Typography>
              </Box>
            </Box>

            <Box className={classes.controls}>
              <Hidden mdUp={true}>
                <PlayButton
                  onClick={playButtonClick}
                  loading={player.loading}
                  isPlaying={player.playing}
                />
              </Hidden>

              <Hidden smDown={true}>
                <Box width="100%" mr={5}>
                  <Grid alignItems="center" container>
                    <Hidden smDown={true}>
                      <Grid sm={12} md={12} lg={5} xl={5} item>
                        <ModalControls
                          setRefreshName={setRefreshName}
                          refreshName={refreshName}
                          data={data}
                          horizontal
                        />
                      </Grid>
                    </Hidden>

                    <Grid sm={12} md={12} lg={7} xl={7} item>
                      <MusicPlayerSlider horizontal />
                    </Grid>
                  </Grid>
                </Box>

                <VolumeControls />
              </Hidden>

              <Hidden xsDown={true}>
                <Box ml={1}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '-22px',
                      right: '10px',
                      zIndex: 9999,
                    }}
                    onClick={handleClose}
                  >
                    <CloseIcon
                      onClick={() => setShowMusic(false)}
                      style={{
                        color: '#fff',
                        fill: '#fffffff',
                        fontSize: '20px',
                      }}
                    />
                  </IconButton>
                </Box>
              </Hidden>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MusicPlayer;
