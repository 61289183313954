import gql from "graphql-tag";

const userSignUp = gql`
query($type:String){
    findUsers: usersSignupDayWeekMonthYear(type:$type){
        dates,
        data
    }
    }`

export interface userSignupResponse {
    findUsers: StreamerData;
    findAritst: StreamerData;
    findTopAlbum:StreamerData,
    revenueDayWeekMonthYear:TotalRevenue
}
export interface userSignupVariable {
    type: String;
}


export default userSignUp; 