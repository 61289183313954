import {
  createMuiTheme,
  responsiveFontSizes,
  Theme,
} from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FD4060',
      contrastText: '#4F667B',
    },
    secondary: {
      main: '#3D7670',
    },
    text: {
      primary: '#02172A',
      secondary: '#FD4060',
      hint: '#455A6D',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: 44,
    },
    h2: {
      fontSize: 36,
    },
    h3: {
      fontSize: 28,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    body1: {
      fontSize: 13,
    },
    body2: {
      fontSize: 10,
    },
    caption: {
      fontSize: 8,
    },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      fontSize: 8,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiButtonBase: {
      root: {
        textTransform: 'none',
      },
    },
  },
} as Theme);

theme = responsiveFontSizes(theme);

export default theme;
