import { useQuery, useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import StarsIcon from '@material-ui/icons/Stars';
import React, { FC, useEffect } from 'react';
import UserItem from './UserItem';
import VerticalList from './VerticalList';
import findAllArtist, {
  findAllArtistResponse,
} from 'core/graphql/queries/findAllArtist';
import disableArtist, {
  disableArtistResponse,
  disableArtistVariables
} from "core/graphql/mutations/disableArtist"
import { Pagination } from '@material-ui/lab';
import ArtistAvatar from './ArtistAvatar';
import CustomTable from './@ui/CustomTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "../components/@ui/custom.css"
import Star from "../pictures/featureStar.svg"
import Avatar from "../pictures/dummyAvatar.svg"
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import Sort from "../pictures/sort.svg"
import TextField from '@mui/material/TextField';
import SearchArtist, {
  searchAllResponse
} from "../core/graphql/queries/searchArtist"




const limit = 10;


interface propsItem {
  type: String
}

const UserList = (type: any) => {



  const [page, setPage] = React.useState(parseInt(window.location.search.split("=")[1]));
  const [count, setCount] = React.useState(0)
  const [search, setSearch] = React.useState("");
  const [sort, SetSort] = React.useState(false)
  const QueryMultiple = () => {
    const res1 = useQuery<findAllArtistResponse>(
      findAllArtist,
      {
        variables:
          { limit: limit, offset: limit * page - limit, type: type.type },
      }
    );
    const res2 = useQuery<searchAllResponse>(
      SearchArtist, {
      variables: {
        text: search,
        type: type.type
      }
    }
    )

    return [res1, res2]
  }

  const [
    { loading: loading1, data: data1, refetch },
    { loading: loading2, data: data2 },
  ] = QueryMultiple()

  const [list, SetList] = React.useState(data1 ? data1?.allArtistList?.artist : undefined)
  const [listSearch, SetListSearch] = React.useState(data2 ? data2?.searchArtist : undefined)


  // const [options, setOptions] = React.useState('')
  
  const history = useHistory()
  
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    history.push({
      search: `?page=${value}`
    })
  };
  useEffect(() => {
    if (data1 && data1.allArtistList.total % 10 > 0) {
      setCount(Math.floor(data1 ? data1.allArtistList.total / 10 : 1) + 1)
    }
    else {
      setCount(Math.floor(data1 ? data1.allArtistList.total / 10 : 1))
    }
    refetch()
    SetList(data1?.allArtistList?.artist)
    SetListSearch(data2?.searchArtist)
  }, [page, search, type, data1, count,data2]);
  

  
  
  console.log(data1,'hi')
  
  
  const handleClick = (id: any) => {
    history.push(`/artist/${id}`)
  }

  const handleInput = (event: any) => {
    setSearch(event.target.value)
  };

  const useStyles = makeStyles<Theme>((theme) => ({
    container: {
      display: 'flex',
    },
    image: {
      width: '55px',
      height: '55px',
      borderRadius: '5px',
    },
    artistContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem'
    },
    thead: {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
    rows: {
      'nth-of-type(even)': {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
      }
    },
    SearchField: {
      display: "flex",
      alignItems: "center",
      gap: "0.3rem",
      marginBottom: "20px"
    },
    ClearBtn: {
      background: 'none',
      outline: 'none',
      border: 'none',
      color: "#fff",
      fontWeight: 600,
      display: search ? "block" : "none",
    }
  }));

  const classes = useStyles();

  const handleSort = () => {
    SetSort(!sort)
    sort ? SetList(data1?.allArtistList?.artist.slice().sort((a: any, b: any) => b.totalEarn - a.totalEarn)) : SetList(data1?.allArtistList?.artist.slice().sort((a: any, b: any) => a.totalEarn - b.totalEarn))
    sort ? SetListSearch(data2?.searchArtist.slice().sort((a: any, b: any) => b.totalEarn - a.totalEarn)) : SetListSearch(data2?.searchArtist.slice().sort((a: any, b: any) => a.totalEarn - b.totalEarn))
  }








  return (
    <>
      <div className={classes.SearchField}>
        <h2 style={{ color: "#3D7670" }}>Search</h2>
        <TextField className={classes.SearchBox} id="outlined-basic" variant="outlined" value={search} onInput={(e) => handleInput(e)} />
        <button className={classes.ClearBtn} style={{ cursor: 'pointer' }} onClick={() => {
          setSearch("")
        }}>
          Clear
        </button>
      </div>
      {!search ?
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow className={classes.thead}>
                  <TableCell width="5%" style={{ color: 'white' }}>
                    #
                  </TableCell>
                  <TableCell style={{ color: 'white' }}>
                    Name
                  </TableCell>
                  <TableCell style={{ color: 'white' }}>
                  </TableCell>
                  <TableCell style={{ color: 'white' }}>
                    Email
                  </TableCell>
                  <TableCell style={{ color: 'white' }}>
                    StarFarm ID
                  </TableCell>
                  <TableCell style={{ color: 'white' }}>
                    <div style={{ display: 'flex', gap: "0.5rem" }}>
                      Earnings($)
                      <img src={Sort} onClick={handleSort} style={{ cursor: "pointer" }} />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list && list.map((val: any, idx: number) => (
                  <TableRow onClick={() => handleClick(val._id)} className={classes.rows} style={{ cursor: 'pointer' }}>
                    <TableCell style={{ color: 'white' }}>
                      {((page - 1) * 10) + ++idx}
                    </TableCell>
                    <TableCell width={'20%'} style={{ color: 'white' }}>
                      <>
                        <div className={classes.artistContainer}>
                          <img src={val.image === null ? Avatar : val.image} className={classes.image} />
                          {val.displayName}
                        </div>
                      </>
                    </TableCell>
                    <TableCell width={'30%'} style={{ color: 'white' }}>
                      {val.isFeatured ? <>
                        <span className={classes.artistContainer} style={{ gap: '0.3rem' }}>
                          <img src={Star} />
                          Featured Artist
                        </span>
                      </> : ' '}
                    </TableCell>
                    <TableCell width={'30%'} style={{ color: 'white' }}>
                      {val.email}
                    </TableCell>
                    <TableCell width={'30%'} style={{ color: 'white' }}>
                      {val._id}
                    </TableCell>
                    <TableCell className='colorBlue'>
                      {val.totalEarn}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={count} page={page} onChange={handleChange} style={{ marginTop: '20px' }} />
        </>
        : <>
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow className={classes.thead}>
                    <TableCell style={{ color: 'white' }}>
                      #
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      Name
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      StarFarm ID
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      <div style={{ display: 'flex', gap: "0.5rem" }}>
                        Earnings($)
                        <img src={Sort} onClick={handleSort} style={{ cursor: "pointer" }} />
                      </div>
                    </TableCell>
                    <TableCell style={{ color: 'white' }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listSearch && listSearch.map((title: any, idx: number) => (
                    <TableRow>
                      <TableCell style={{ color: 'white' }}>
                        {((page - 1) * 10) + ++idx}
                      </TableCell>
                      <TableCell>
                        <>
                          <div className={classes.artistContainer}>
                            <img src={title.image === null ? Avatar : title.image} className={classes.image} />
                            {title.displayName}
                          </div>
                        </>

                      </TableCell>
                      <TableCell>
                        {title._id}
                      </TableCell>
                      <TableCell className='colorBlue'>
                        {title.totalEarn}
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} onClick={() => handleClick(title._id)}>
                        View
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>}
    </>
  );
};

export default UserList;
