import gql from "graphql-tag";

const SearchArtist = gql`
query($text:String,$type:String){
  searchArtist(type:$type,searchText:$text){
    displayName,
    image,
    _id,
    isFeatured,
    totalEarn,
    earnByPlay,
    tip
  }
}
`

export interface searchAllResponse {
    searchUser:any
    allArtistList: AllArtistSchema;
    allUserList:StreamersSchema;
    findAlbumMany: Album[];
    findAlbumWithEarning:AllArtistSchema;
    findSongsWithEarning:AllArtistSchema
    searchArtist:any
    searchSong:any
    totalRevenue: totalRevenueInterface
}

export interface searchAllVariable {
    type:String,
    text:String
}

export default SearchArtist