import gql from "graphql-tag";

const SearchSong = gql`
query($type:String,$text:String){
  searchSong(searchText:$text,type:$type){
    title,
    songEarning,
    image,
    _id,
    starfarmEarning,
    playSong,
    costPerPlay
  }
}
`

export interface searchAllResponse {
    searchUser:any
    allArtistList: AllArtistSchema;
    allUserList:StreamersSchema;
    findAlbumMany: Album[];
    findAlbumWithEarning:AllArtistSchema;
    findSongsWithEarning:AllArtistSchema
    searchArtist:any
    searchSong:any
    totalRevenue: totalRevenueInterface
}

export interface searchAllVariable {
    type:String,
    text:String
}

export default SearchSong;