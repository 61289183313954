import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import FeaturedAlbum from 'components/FeaturedAlbum';
import FeaturedArtist from 'components/FeaturedArtist';
import UserList from 'components/UserList';
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PendingApprovalBox from "../components/PendingApprovalBox"
import { Autocomplete } from '@material-ui/lab';
import BellIcon from "../pictures/belly.svg"


const useStyle = makeStyles<Theme>((theme) => ({
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: '20px',
  },
  title1: { color: '#02172A', fontWeight: 'bold' },
  inactiveSelector: { color: '#455A6D', fontWeight: 'bold' },
  activeSelector: { color: '#fff', fontWeight: 'bold',fontSize:"24px" },
}));

const UsersPage: FC = (props) => {
  const [choose, setChoose] = useState(true);
  const classes = useStyle();
  const [type, setType] = useState("week")
  const [age, setAge] = useState('');

  // useEffect(()=>{
  //   console.log("called")
  // },[type])

  const history = useHistory()


  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleNotification = () => {
    history.push("/notifications")
  }





  return (
    <>
      <Box display="flex" flexDirection="column" padding="20px 50px" marginBottom="20px">
        <Box marginTop="10px">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography
              variant={choose ? 'h4' : 'h6'}
              className={classes.activeSelector}
            >
              Artists
            </Typography>
            <div style={{ display: 'block'}}>
              {/* <div style={{ cursor: 'pointer',textAlign:'end',marginBottom:'20px' }} onClick={handleNotification}>
                <img src={BellIcon} />
              </div> */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: '0 2px 3px', fontSize: '14px', color: "#3D7670" }}>
                  Display data for
                </p>
                <FormControl variant="standard" sx={{ m: 1, width: 150 }} style={{ fontSize: '12px' }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={type}
                    onChange={handleChange}
                    label="Age"
                  >
                    <MenuItem value={"week"}>Past 7 Days</MenuItem>
                    <MenuItem value={"month"}>Past Month</MenuItem>
                    <MenuItem value={"year"}>Past Year</MenuItem>
                    <MenuItem value={"allTime"}>All Time</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>


          <UserList type={type} />

        </Box>
      </Box>

    </>
  );
};

export default UsersPage;
