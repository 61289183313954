import React from 'react';
import RepeatIcon from '@material-ui/icons/Repeat';
import { observer } from 'mobx-react-lite';
import usePlayer from './PlayerContext';
import RepeatOneIcon from "@material-ui/icons/RepeatOne";


interface Props {
  small?: boolean;
}

const RepeatButton: React.FC<Props> = ({ small = false }) => {
  const player = usePlayer();

  if (player.repeat === true) {
    return (
      <RepeatIcon
        style={{ fontSize: small ? '18px' : '2.75vh', fill: '#b74260' }}
      />
    );
  }

  if (player.repeat1 === true) {
    return (
      <RepeatOneIcon
        style={{ fontSize: small ? '18px' : '2.75vh', fill: '#b74260' }}
      />
    );
  }

  return (
    <RepeatIcon
      style={{ fontSize: small ? "18px" : "2.75vh", fill: "#ffffff4d" }}
    />
  );
};

export default observer(RepeatButton);
