import gql from 'graphql-tag';

const findScheduledJobs = gql`
  query {
    scheduledJobs: findAdminJobs {
      name
      nextRunDate
    }
  }
`;

export interface findScheduledJobsResponse {
  scheduledJobs: ScheduledJob[];
}

export default findScheduledJobs;
