import gql from "graphql-tag";

const findTopArtist = gql`
query($type:String){
    findTopArtist(type:$type,limit:5){
    artistName,
    image
    _id
    }
}
`
export interface findTopArtistVariable {
    type: String;
}


export default findTopArtist