import React, { useContext, useState } from 'react';
import { createStyles, makeStyles, withStyles, Typography,Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import TokenManager from 'core/auth/TokenManager';
import MoreButton from './@ui/MoreButton';
import envs from 'config/envs';
import ConfirmAction from 'stores/ConfirmAction';
import ConfirmActionPopUp from './@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';
import logout from "../pictures/logout.svg"

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&::hover': {
      backgroundColor: 'transparent',
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: { fill: '#2E7362' },
    logoutContainer:{
      width: "100%",
      display: 'flex',
      position: "relative",
      gap:'20px',
      marginBottom:'20px',      
    },
    logoutText:{
      marginLeft:"20px"
    }
  })
);

const ProfileSettingsButton = () => {
  const manager = useContext(TokenManager);

  const handleConfirmLogOut = async () => {
    await manager.clearToken();
    window.location.href='https://app.starfarm.com'
  };

  const [confirmActionStore] = useState(new ConfirmAction());

  const handleLogOut = () => {
    confirmActionStore.setMessage('Are you sure you want to log out?');
    confirmActionStore.setTitle('Log Out');
    confirmActionStore.setConfirmOpen(true);
  };

  const classes = useStyles();

  return (
    <>
      <StyledMenuItem onClick={handleLogOut}>
        <Box className={classes.logoutContainer}>
          <img src={logout} alt="logoutimage" />
          <Typography
            variant="h4"
          >
            Log Out
          </Typography>
        </Box>
      </StyledMenuItem>
      <ConfirmActionPopUp
        context={confirmActionStore}
        actionOnConfirm={handleConfirmLogOut}
      />
    </>
  );
};

export default observer(ProfileSettingsButton);
