import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import getTimeFromNow from 'core/utils/getTimeFromNow';
import React, { FC, useContext, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import EventMessageContext from 'stores/EventMessage';
import ConfirmAction from 'stores/ConfirmAction';
import { useMutation } from '@apollo/client';
import runJob, {
  runJobResponse,
  runJobVariables,
} from 'core/graphql/mutations/runJob';
import findJobHistoryMany from 'core/graphql/queries/findJobHistoryMany';
import ConfirmActionPopUp from './@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    // backgroundColor: '#4F667B',
    color: 'white',
    borderRadius: '100px',
    padding: '2px 5px',
    fontSize: '10px',
  },
  loading: {
    width: '50px',
    height: '30px',
    borderRadius: '100px',
  },
  jobsTitle:{
    margin:"0",
    marginBottom:'5px',
    fontSize:"16px"
  },
  subtitle:{
    margin:"0",
    fontSize:"12px"


  }
}));

interface IScheduledJobItemProps {
  job?: ScheduledJob;
  loading?: boolean;
}

const ScheduledJobItem: FC<IScheduledJobItemProps> = ({
  job,
  loading = false,
}) => {
  const [confirmActionStore] = useState(new ConfirmAction());

  const [runJobRequest] = useMutation<runJobResponse, runJobVariables>(runJob, {
    refetchQueries: [{ query: findJobHistoryMany }],
  });

  const alertManager = useContext(EventMessageContext);

  const timeTilRun = getTimeFromNow(job?.nextRunDate!);

  const classes = useStyles();

  const openConfirm = () => {
    confirmActionStore.setTitle('Run Job');
    confirmActionStore.setMessage(
      `Are you sure you want to run the job :\n${job?.name}?`
    );
    confirmActionStore.setConfirmOpen(true);
  };

  const handleRunJob = async () => {
    try {
      const response = await runJobRequest({
        variables: {
          jobType: job?.name!,
        },
      });

      alertManager.setEvent(
        response.data?.response === 'Successfully ran job.'
          ? 'success'
          : 'error',
        response.data?.response || 'Had trouble running request'
      );
    } catch (err) {
      alertManager.setEvent('error', 'Failed to run job.');
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <div>
            <h3 className={classes.jobsTitle}>
              {job?.name}
            </h3>
            <p className={classes.subtitle}>
              {`Scheduled to run in ${timeTilRun}`}
            </p>
          </div>
          {/* <Typography
            style={{ fontWeight: 'bold', color: '#455A6D' }}
            color="secondary"
          >
            {job?.name}
          </Typography>
          <Typography>
            {`Scheduled to run in ${timeTilRun}`}
          </Typography> */}
        </Box>

        {
          <Button onClick={openConfirm} className={classes.button}>
            RUN
          </Button>
        }
      </Box>

      <ConfirmActionPopUp
        context={confirmActionStore}
        actionOnConfirm={handleRunJob}
      />
    </>
  );
};

export default observer(ScheduledJobItem);
