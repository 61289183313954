import { observable, action } from 'mobx';
import { createContext } from 'react';

class EventMessageManager {
  @observable
  public severityType?: string;

  @observable
  public message?: string;

  @action
  public setEvent = (severityType: string, message: string) => {
    console.log('set event');
    this.severityType = severityType;

    this.message = message;

    if (severityType !== 'error') {
      setTimeout(this.clearEvent, 5000);
    }
  };

  @action
  public clearEvent = () => {
    this.severityType = undefined;
    this.message = undefined;
  };
}

export const EventMessageStore = new EventMessageManager();

const EventMessageContext = createContext(EventMessageStore);

export default EventMessageContext;
