import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';
import findPendingApprovalArtist, {
    findPendingApprovalResponse,
} from 'core/graphql/queries/findPendingApprovalArtist';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import findSongEarning, {
    findAlbumManyResponse,
    findAlbumManyVariables
} from "../core/graphql/queries/findSongByEarning"
import { Pagination } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SearchSong, {
    searchAllResponse
} from "../core/graphql/queries/searchSong"
import Sort from "../pictures/sort.svg"
import totalRevenue, {
    newUSerResponse,
    newUSerVariable
} from "../core/graphql/queries/findTotalRevenue"




const EarningListStreaming = (type: any) => {
    const limit = 10;

    const [page, setPage] = React.useState(parseInt(window.location.search.split("=")[1].split("&")[0]));
    const [search, setSearch] = React.useState("");
    const [sort, SetSort] = React.useState(false)
    const QueryMultiple = () => {
        const res1 = useQuery<findAlbumManyResponse>(findSongEarning,
            {
                variables:
                    { limit: limit, offset: limit * page - limit, type: type.type },
            });

        const res2 = useQuery<searchAllResponse>(
            SearchSong, {
            variables: {
                type: type.type,
                text: search
            }
        }
        )
        const res3 = useQuery<newUSerResponse>(totalRevenue, {
            variables: {
                type: type.type
            }

        })

        return [res1, res2, res3]
    }

    const [
        { loading: loading1, data: data1, refetch },
        { loading: loading2, data: data2 },
        { loading: loading3, data: data3 },
    ] = QueryMultiple()


    // const { data, loading, refetch } = 
    const [list, SetList] = React.useState(data1 ? data1?.findSongsWithEarning?.songs : undefined)
    const [listSearch, SetListSearch] = React.useState(data2 ? data2?.searchArtist : undefined)


    useEffect(() => {
        refetch()
        SetList(data1?.findSongsWithEarning?.songs)
        SetListSearch(data2?.searchSong)
    }, [page, search, type, data1, data2]);


    const history = useHistory()
    let count = data1 ? Math.floor(data1?.findSongsWithEarning?.total / 10) : 1;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        history.push({
            search: `?page=${value}&tabs=${window.location.search.split("=")[2]}`
        })
        setPage(value);
    };





    const handleInput = (event: any) => {
        setSearch(event.target.value)
    };
    const useStyles = makeStyles<Theme>((theme) => ({
        container: {
            display: 'flex',
        },
        image: {
            width: '55px',
            height: '55px',
            borderRadius: '5px',
        },
        artistContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '1rem'
        },
        SearchField: {
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
            margin: "20px 0"
        },
        ClearBtn: {
            background: 'none',
            outline: 'none',
            border: 'none',
            color: "#fff",
            fontWeight: 600,
            display: search ? "block" : "none",

        }
    }));


    const handleSort = (val: any) => {
        SetSort(!sort)
        sort ? SetList(data1?.findSongsWithEarning?.songs.slice().sort((a: any, b: any) => b[val] - a[val])) : SetList(data1?.findSongsWithEarning?.songs.slice().sort((a: any, b: any) => a[val] - b[val]))
        sort ? SetListSearch(data2?.searchSong.slice().sort((a: any, b: any) => b[val] - a[val])) : SetListSearch(data2?.searchSong.slice().sort((a: any, b: any) => a[val] - b[val]))

    }

    const handleClick = (id: any) => {
        history.push(`/song/${id}`)
    }

    // console.log(data1?.findSongsWithEarning,'hello')

    const classes = useStyles();

    return (
        <>
            <div>
                <h2 style={{fontSize:"24px"}}>
                    ${data3?.totalRevenue.byPlay}
                </h2>
            </div>
            <div className={classes.SearchField}>
                <h2 style={{ color: "#3D7670" }}>Search</h2>
                <TextField className={classes.SearchBox} id="outlined-basic" variant="outlined" value={search} onInput={(e) => handleInput(e)} />
                <button className={classes.ClearBtn} style={{ cursor: 'pointer' }} onClick={() => {
                    setSearch("")
                }}>
                    Clear
                </button>
            </div>


            {!search ?
                <>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead>
                                <TableRow style={{ color: 'white !important' }}>
                                    <TableCell>
                                        #
                                    </TableCell>
                                    <TableCell width="40%">
                                        Title
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                                            Price
                                            <img src={Sort} onClick={() => handleSort("costPerPlay")} style={{ cursor: "pointer" }} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                                            Plays
                                            <img src={Sort} onClick={() => handleSort("playSong")} style={{ cursor: "pointer" }} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                                            Artist's Earnings(75%)
                                            <img src={Sort} onClick={() => handleSort("songEarning")} style={{ cursor: "pointer" }} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: "0.5rem" }}>
                                            StarFarm's Earnings(25%)
                                            <img src={Sort} onClick={() => handleSort("starfarmEarning")} style={{ cursor: "pointer" }} />
                                        </div>

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list && list.map((val: any, idx: number) => (
                                    <TableRow>
                                        <TableCell>
                                            {((page - 1) * 10) + ++idx}
                                        </TableCell>
                                        <TableCell>
                                            <>
                                                <div className={classes.artistContainer}>
                                                    <img src={val.image}
                                                        className={classes.image} />
                                                    {val.title}
                                                </div>

                                            </>
                                        </TableCell>
                                        <TableCell className='colorBlue'>
                                            $ {val.costPerPlay / 100}
                                        </TableCell>
                                        <TableCell>
                                            {val.playSong}
                                        </TableCell>
                                        <TableCell className='colorBlue'>
                                            $ {val.songEarning}
                                        </TableCell>
                                        <TableCell className='colorBlue'>
                                            $ {val.starfarmEarning}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination count={count + 1} page={page} onChange={handleChange} style={{ marginTop: '20px' }} />
                </>
                :
                <>
                    <div>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow className={classes.thead}>
                                        <TableCell>
                                            #
                                        </TableCell>
                                        <TableCell width="40%">
                                            Title
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: "0.5rem" }}>
                                                Price
                                                <img src={Sort} onClick={() => handleSort("costPerPlay")} style={{ cursor: "pointer" }} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: "0.5rem" }}>
                                                Plays
                                                <img src={Sort} onClick={() => handleSort("playSong")} style={{ cursor: "pointer" }} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: "0.5rem" }}>
                                                Artist's Earnings(75%)
                                                <img src={Sort} onClick={() => handleSort("songEarning")} style={{ cursor: "pointer" }} />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: 'flex', gap: "0.5rem" }}>
                                                StarFarm's Earnings(25%)
                                                <img src={Sort} onClick={() => handleSort("starfarmEarning")} style={{ cursor: "pointer" }} />
                                            </div>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listSearch && listSearch.map((val: any, idx: number) => (
                                        <TableRow>
                                            <TableCell>
                                                {((page - 1) * 10) + ++idx}
                                            </TableCell>
                                            <TableCell>
                                                <>
                                                    <div className={classes.artistContainer}>
                                                        <img src={val.image}
                                                            className={classes.image} />
                                                        {val.title}
                                                    </div>

                                                </>
                                            </TableCell>
                                            <TableCell className='colorBlue'>
                                                $ {val.costPerPlay / 100}
                                            </TableCell>
                                            <TableCell>
                                                {val.playSong}
                                            </TableCell>
                                            <TableCell className='colorBlue'>
                                                $ {val.songEarning}
                                            </TableCell>
                                            <TableCell className='colorBlue'>
                                                $ {val.starfarmEarning}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div></>}
        </>
    );
};

export default EarningListStreaming;
