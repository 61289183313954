import { useLazyQuery } from '@apollo/client';
import EmptyDataMessage from 'components/@ui/EmptyDataMessage';
import ListItem from 'components/@ui/ListItem';
import SEARCH_BY_TERM, {
  SEARCH_BY_TERMResponse,
  SEARCH_BY_TERMVariables,
} from 'core/graphql/mutations/searchByTerm';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SearchContext from 'stores/Search';

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    fill: '#E7EFF5',
    height: '150px',
    width: '150px',
  },
}));

interface ISearchPageProps extends RouteComponentProps {}

const SearchPage: FC<ISearchPageProps> = ({ location }) => {
  const classes = useStyles();

  const searchManager = useContext(SearchContext);

  const searchTerm = useMemo(() => location.search.slice(2), [location.search]);

  const [searchTimeOut, setSearchTimeOut] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const [search, { data, loading }] = useLazyQuery<
    SEARCH_BY_TERMResponse,
    SEARCH_BY_TERMVariables
  >(SEARCH_BY_TERM);

  const handleSearch = async () => {
    if (!searchTerm || searchTerm.length < 1) {
      return;
    }

    try {
      await search({
        variables: {
          term: searchTerm,
        },
      });
      return;
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = () => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    setSearchTimeOut(
      setTimeout(() => {
        handleSearch();
      }, 1000)
    );
  };

  useEffect(() => {
    handleChange();

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      {loading &&
        [...Array(8)].map((e, index) => (
          <ListItem loading={loading} key={index} />
        ))}

      {!loading &&
        data?.searchResponse &&
        data.searchResponse.length > 0 &&
        data.searchResponse.map((item) => (
          <ListItem
            artist={item.responseType === 'ARTIST' ? true : false}
            image={item.image}
            descriptionTitle={
              item.responseType.charAt(0).toUpperCase() +
              item.responseType.slice(1).toLocaleLowerCase()
            }
            primaryTitle={item.name}
            type={item.responseType === 'ARTIST' ? 'artist' : 'album'}
            id={item.responseType === 'SONG' ? item.albumId : item.id}
            weeklyPlays={item.plays}
            key={item.id}
            extraOnClick={() => searchManager.setSearchTerm('')}
          />
        ))}

      {!loading && (!data || data?.searchResponse.length < 1) && (
        <EmptyDataMessage
          message="Search Artists, Albums, and Songs"
          icon={<SearchIcon className={classes.icon} />}
        />
      )}
    </>
  );
};

export default SearchPage;
