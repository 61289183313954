import gql from 'graphql-tag';

const approveSong = gql`
  mutation approveSong($songId: MongoID!) {
    approveededSong: approveSong(_id: $songId) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface approveSongResponse {
  approveededSong: {
    record: Album;
  };
}

export interface approveSongVariables {
  songId: string;
}

export default approveSong;
