import { useContext } from "react";
import { PlayerContext } from "./PlayerContextContainer";

const usePlayer = () => {
  const player = useContext(PlayerContext);

  return player;
};

export default usePlayer;
