import gql from "graphql-tag";


const findArtistEarning = gql`
query($id:String){
    findArtistTotalEarning(artistId:$id){
     totalEarnInDollar
   }
 }
   `


export interface findArtistDetailsResponse {
    artist: Artist;
    artistDetails: ArtistDetails;
    findArtistTotalEarning:Artist;
}

export interface findArtistDetailsVariables {
    id: String;
}

export default findArtistEarning;