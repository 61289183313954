import gql from 'graphql-tag';

const suspendArtist = gql`
  mutation suspendArtist($artistId: String) {
    suspendArtist(artistId: $artistId) {
      _id
      status
    }
  }
`;

export interface suspendArtistResponse {
  artist: Artist;
}

export interface suspendArtistVariables {
  artistId: string;
}

export default suspendArtist;
