import gql from 'graphql-tag';

const unfeatureAlbum = gql`
  mutation unfeatureAlbum($id: MongoID!) {
    unfeaturedAlbum: unfeatureAlbum(_id: $id) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface unfeatureAlbumResponse {
  unfeaturedAlbum: {
    record: Album;
  };
}

export interface unfeatureAlbumVariables {
  id: string;
}

export default unfeatureAlbum;
