import gql from 'graphql-tag';

const unfeatureArtist = gql`
  mutation unfeatureArtist($id: MongoID!) {
    unfeaturedArtist: unfeatureArtist(_id: $id) {
      record {
        _id
        isFeatured
      }
    }
  }
`;

export interface unfeatureArtistResponse {
  unfeaturedArtist: {
    record: Artist;
  };
}

export interface unfeatureArtistVariables {
  id: string;
}

export default unfeatureArtist;
