import gql from 'graphql-tag';

const findStreamerById = gql`
  query ($customerId: String) {
    streamer: findCustomerInformation(customerId: $customerId) {
      firstName,
      lastName,
      balanceInDollars,
      dateOfSignup,
      spentInDollar,
      tipInDollar,
      songPlayed,
      email,
    }
  }
`;

export interface findStreamerByIdResponse {
  streamer: Streamer;
}

export default findStreamerById;

