import { observable, action } from 'mobx';
import { createContext } from 'react';

class SearchManager {
  @observable
  public searchTerm?: string;

  @action
  public setSearchTerm = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  @action
  public clearSearch = () => {
    this.searchTerm = undefined;
  };
}

export const SearchStore = new SearchManager();

const SearchContext = createContext(SearchStore);

export default SearchContext;
