import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useHistory } from 'react-router';
import Skeleton from '@material-ui/lab/Skeleton';
import CountCircle from 'components/CountCircle';
import FlagIcon from '@material-ui/icons/Flag';

interface IStylesProps {
  image?: string;
  artist: boolean;
}

const useStyles = makeStyles<Theme, IStylesProps>((theme) => ({
  image: {
    backgroundImage: (props) => `url(${props.image})`,
    minHeight: '40px',
    minWidth: '40px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: (props) => (props.artist ? '100px' : 'none'),
    marginRight: '10px',
    marginLeft: '20px',
  },
  view: {
    color: '#FD4060',
    minWidth: '40px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
  textContainer: {
    maxWidth: '100%',
    width: '50%',
    overflow: 'hidden',
  },
  textStyle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  loading: {
    height: '40px',
    width: '40px',
    marginRight: '10px',
    transform: 'scale(1,1)',
    marginLeft: '20px',
  },
  loadingFlagged: {
    height: '30px',
    width: '50px',
    marginRight: '10px',
    transform: 'scale(1,1)',
    marginLeft: '20px',
  },
}));

interface IListItemProps {
  image?: string;
  primaryTitle?: string;
  descriptionTitle?: string;
  weeklyPlays?: number;
  artist?: boolean;
  type?: string;
  id?: string;
  loading?: boolean;
  extraOnClick?: () => void;
  flagCount?: number;
  toFlags?: boolean;
}

const ListItem: FC<IListItemProps> = ({
  image,
  primaryTitle,
  descriptionTitle,
  weeklyPlays,
  artist = false,
  type,
  id,
  loading = false,
  extraOnClick,
  flagCount,
  toFlags = false,
}) => {
  const classes = useStyles({ image, artist });

  const history = useHistory();
  const HandleView = () => {
    if (extraOnClick) {
      extraOnClick();
    }
    history.push(toFlags ? `/${type}/${id}/#flags` : `/${type}/${id}`);
  };

  return (
    <Box className={classes.container}>
      <Box width="75%" display="flex" alignItems="center">
        {flagCount &&
          (loading ? (
            <Skeleton className={classes.loadingFlagged} />
          ) : (
            <Box display="flex" alignItems="center" flexDirection="row">
              <CountCircle number={flagCount} />

              <Box ml={1} mr={1}>
                <FlagIcon style={{ fill: '#FD4060', height: '20px' }} />
              </Box>
            </Box>
          ))}

        {loading ? (
          <Skeleton className={classes.loading} />
        ) : (
          <Box className={classes.image} />
        )}

        <Box mr={weeklyPlays ? 2 : 0} className={classes.textContainer}>
          <Typography
            className={classes.textStyle}
            variant="body1"
            style={{ color: '#02172A', fontWeight: 'bold' }}
          >
            {loading ? <Skeleton /> : primaryTitle}
          </Typography>

          <Typography
            className={classes.textStyle}
            variant="subtitle1"
            style={{ color: '#455A6D' }}
          >
            {loading ? <Skeleton /> : descriptionTitle}
          </Typography>
        </Box>
      </Box>

      <Box
        width="25%"
        maxWidth="25%"
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {weeklyPlays && (
          <>
            <Typography align="left" variant="body2">
              {loading ? <Skeleton /> : weeklyPlays}
            </Typography>
            <PlayArrowIcon />
          </>
        )}

        <Typography
          align="right"
          className={classes.view}
          onClick={HandleView}
          variant="body1"
        >
          {loading ? <Skeleton /> : 'View'}
        </Typography>
      </Box>
    </Box>
  );
};

export default ListItem;
