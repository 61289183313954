import React, { FC, useEffect, useState, useContext } from 'react';

import { Redirect } from 'react-router-dom';

import TokenManager from 'core/auth/TokenManager';

import envs from 'config/envs';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import jwt from 'jsonwebtoken';

interface ITokenValidationProps {}

enum TokenExpiredEnum {
  CHECKING,
  EXPIRED,
  NOTEXPIRED,
  UNAUTHORIZED,
}

const TokenValidation: FC<ITokenValidationProps> = ({ children }) => {
  const manager = useContext(TokenManager);

  const [isValid, setIsValid] = useState<TokenExpiredEnum>(
    TokenExpiredEnum.CHECKING
  );

  const [graphqlClient, setGraphqlClient] =
    useState<ApolloClient<any> | undefined>();

  useEffect(() => {
    const token = manager.getToken();

    manager.setToken(token);

    if (token === undefined) {
      setIsValid(TokenExpiredEnum.EXPIRED);
      return;
    }

    manager.storeToken(token);

    const adminToken = jwt.decode(token) as Token;

    setIsValid(TokenExpiredEnum.NOTEXPIRED);

    if (adminToken.isAdmin === false) {
      setIsValid(TokenExpiredEnum.UNAUTHORIZED);
    }

    const client = new ApolloClient({
      uri: envs.apiBaseUrl + '/graphql',
      cache: new InMemoryCache(),
      headers: {
        authorization: token,
      },
    });

    setGraphqlClient(client);
    // if it hasnt add the token to api client headers
  }, [manager]);

  if (isValid === TokenExpiredEnum.CHECKING) {
    return <div />;
  }

  if (isValid === TokenExpiredEnum.EXPIRED) {
    return (
      <Redirect to={`/unauthenticated?redirectUrl=${window.location.href}`} />
    );
  }

  if (isValid === TokenExpiredEnum.UNAUTHORIZED) {
    return <Redirect to="/unauthorized" />;
  }

  if (!graphqlClient) {
    return <div />;
  }

  return <ApolloProvider client={graphqlClient}>{children}</ApolloProvider>;
};

export default TokenValidation;
