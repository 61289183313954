import React, { FC, useEffect } from 'react';

import envs from 'config/envs';

interface IUnauthenticatedProps {}

const Unauthenticated: FC<IUnauthenticatedProps> = (props) => {
  const redirectPath = window.location.search;
  useEffect(() => {
    window.location.href = envs.streamerUri + `/login${redirectPath}` || '#';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Unauthenticated;
