import { useQuery } from '@apollo/client';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import findPendingApprovalArtist, {
    findPendingApprovalResponse,
} from 'core/graphql/queries/findPendingApprovalArtist';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from "../pictures/dummyAvatar.svg"
import { Button } from '@mui/material';
import ConfirmAction from 'stores/ConfirmAction';
import { useApolloClient } from '@apollo/client';
import ArtistActionMenuManager from 'stores/ArtistActionMenu';
import ConfirmActionPopUp from './@ui/ConfirmAction';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';


const limit = 10;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        display: 'flex',
    },
    image: {
        width: '55px',
        height: '55px',
        borderRadius: '5px',
    },
    artistContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    acceptBtn: {
        backgroundColor: '#0ED3BF',
        color: "white",
        borderRadius: '4px',
        padding: '8px 15px',
        fontSize: '16px'
    },
    declineBtn: {
        backgroundColor: 'transparent',
        color: "white",
        border: '1px solid white',
        borderRadius: '4px',
        padding: '8px 15px',
        fontSize: '16px'
    },
    rows: {
        color: 'white',
        '&:hover ': {
            color: "#FD4060",
            cursor: "pointer",
        }
    },
}));

const ArtistApprovalList: FC = () => {
    const [page, setPage] = React.useState(1);
    const [id, setId] = useState('');
    const { data, loading, refetch } = useQuery(findPendingApprovalArtist);

    useEffect(() => {
        refetch();
    }, [page]);

    const history = useHistory()

    const classes = useStyles();
    const client = useApolloClient();

    const [ArtistActionMenuStore] = useState(
        new ArtistActionMenuManager(client, id, '')
    );

    const [confirmActionStore] = useState(new ConfirmAction());

    const handleClick = (id: any) => {
        history.push(`/artists/${id}`)
    }

    return (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableBody>

                        {data?.findPendingApproval?.data.length ? data?.findPendingApproval?.data.map((val: any, idx: number) => (
                            <TableRow style={{ cursor: 'pointer' }} key={idx} onClick={() => handleClick(val?.artist?._id)}>
                                <TableCell width={"5%"}>
                                    {++idx}
                                </TableCell>
                                <TableCell width={"70%"}>
                                    <>
                                        <div className={classes.artistContainer}>
                                            {<img src={val.artist?.image ? val.artist?.image : Avatar} className={classes.image} />}
                                            {val.artist ? val.artist?.displayName : "N.A"}
                                        </div>
                                    </>
                                </TableCell>

                                <TableCell className={classes.rows}>
                                    <>
                                        View
                                    </>
                                </TableCell>
                            </TableRow>
                        )) : <>
                            <h2 style={{fontSize:'18px'}}>
                                No Data Found
                            </h2>
                        </>}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmActionPopUp
                context={confirmActionStore}
                actionOnConfirm={ArtistActionMenuStore.actionToRun!}
            />
        </>
    );
};

export default observer(ArtistApprovalList);
