import Box from '@material-ui/core/Box/Box';
import Slider from '@material-ui/core/Slider/Slider';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import { observer } from 'mobx-react-lite';
import React, { FC, useState, useEffect } from 'react';
import convertTime from './ConvertTime';
import usePlayer from './PlayerContext';

interface SliderProps {
  hideTimers?: boolean;
  horizontal?: boolean;
}

interface UseStlyesParams {
  hideTimers: boolean;
  horizontal: boolean;
}

const useStyles = ({ hideTimers }: UseStlyesParams) =>
  makeStyles((theme) =>
    createStyles({
      mainContainer: {
        display: hideTimers ? 'flex' : 'inline-block',
        alignItems: 'center',
        width: '100%',
      },
      timerContainer: {
        width: hideTimers ? '5%' : '100%',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        marginLeft: hideTimers ? '10px' : '0px',
      },
      text: {
        fontSize: hideTimers ? '11px' : 'inherit',
        color: hideTimers ? 'rgba(255,255,255,0.75)' : 'inherit',
      },
    })
  );

const MusicPlayerSlider: FC<SliderProps> = ({
  hideTimers = false,
  horizontal = false,
}) => {
  const player = usePlayer();
  const classes = useStyles({ hideTimers, horizontal })();
  const currentTime = convertTime(player.currentTime || 0);
  const remainingTime = convertTime(player.timeRemaining || 0);
  const [slide, valS] = useState(0);
  const [remainSlide, remainValS] = useState(0);
  const [slideMove, isSlideMove] = useState(false);
  const [updateMusicSlider, setUpdateMusicSlider] = useState<boolean>(false);
  const sliderVal = convertTime(slide || 0);
  const remainingSliderVal = convertTime(remainSlide || 0);

  return (
    <Box className={classes.mainContainer}>
      {horizontal === false && (
        <Slider
          onChangeCommitted={(e, v) => player.seek(v as number)}
          onChange={(e, v) => {
            player.currentTime = v as number;
          }}
          min={0}
          max={player.songDuration || 0}
          value={player.currentTime || 0}
          color="primary"
        />
      )}

      <Box className={classes.timerContainer}>
        <Typography
          color="textSecondary"
          variant="caption"
          className={classes.text}
        >
          {slideMove ? sliderVal ?? '0:00' : currentTime || '0:00'}
        </Typography>

        {horizontal && (
          <Slider
            onChangeCommitted={(e, v) => {
              player.play();
              if (player.songDuration ? v === player.songDuration ?? 0 : 0) {
                player.next();
              }
              // setUpdateMusicSlider(!updateMusicSlider);
              setTimeout(() => {
                isSlideMove(false);
              }, 500);
            }}
            onChange={(e, v: any) => {
              player.pause();
              isSlideMove(true);
              player.seek(v as number);
              valS((player.currentTime = v as number));
              const evaluatedTime = player.songDuration
                ? player.songDuration - v
                : 0;
              remainValS(evaluatedTime);
              setUpdateMusicSlider(!updateMusicSlider);
            }}
            min={0}
            style={{ width: '80%' }}
            max={player.songDuration || 0}
            value={slideMove ? slide : player.currentTime || 0}
            color="primary"
          />
        )}

        {hideTimers === false && (
          <Typography
            color="textSecondary"
            variant="caption"
            className={classes.text}
          >
            {`-${
              slideMove ? remainingSliderVal ?? '0:00' : remainingTime || '0:00'
            }`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default observer(MusicPlayerSlider);
