import React, { FC } from "react";

import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IPlayButtonProps {
  isPlaying?: boolean;
  loading?: boolean;
  onClick: () => void;
  fontSize?: "small" | "large";
}

const PlayButton: FC<IPlayButtonProps> = ({
  isPlaying,
  loading,
  onClick,
  fontSize,
}) => {  
  const handleClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    onClick();
    e.stopPropagation();
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {isPlaying ? (
        <PauseIcon fontSize={fontSize} onClick={handleClick} />
      ) : (
        <PlayIcon fontSize={fontSize} onClick={handleClick} />
      )}
    </>
  );
};

export default PlayButton;
