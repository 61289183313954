import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface ICountCircleProps {
  loading?: boolean;
  number: number;
}

const CountCircle: FC<ICountCircleProps> = ({ loading = false, number }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontWeight="bold"
      height="25px"
      width="25px"
      style={{
        borderRadius: '50%',
        color: '#FD4060',
      }}
    >
      {loading ? (
        <CircularProgress
          style={{ height: '15px', width: '15px', color: '#FD4060' }}
        />
      ) : (
        <Typography variant="h6">{number}</Typography>
      )}
    </Box>
  );
};

export default CountCircle;
