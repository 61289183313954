import gql from 'graphql-tag';

const suspendAlbum = gql`
  mutation suspendAlbum($albumId: String) {
    suspendAlbum(albumId: $albumId) {
      _id
      status
    }
  }
`;

export interface suspendAlbumResponse {
  album: Album;
}

export interface suspendAlbumVariables {
  albumId: string;
}

export default suspendAlbum;
