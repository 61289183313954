import { ApolloClient } from '@apollo/client';
import { action, observable } from 'mobx';

import approveArtist, {
  approveArtistResponse,
  approveArtistVariables,
} from 'core/graphql/mutations/approveArtist';
import featureArtist, {
  featureArtistResponse,
  featureArtistVariables,
} from 'core/graphql/mutations/featureArtist';
import rejectArtist, {
  rejectArtistResponse,
  rejectArtistVariables,
} from 'core/graphql/mutations/rejectArtist';
import suspendArtist, {
  suspendArtistResponse,
  suspendArtistVariables,
} from 'core/graphql/mutations/suspendArtist';
import unfeatureArtist, {
  unfeatureArtistResponse,
  unfeatureArtistVariables,
} from 'core/graphql/mutations/unfeatureArtist';
import findArtistById from 'core/graphql/queries/findArtistById';
import disableArtist, { disableArtistResponse, disableArtistVariables }  from 'core/graphql/mutations/disableArtist';

class ArtistActionMenu {
  constructor(
    private client: ApolloClient<object>,
    public id: string,
    public userId: string
  ) {}

  @observable
  public actionToRun?: () => Promise<void>;

  @action
  public setActionToRun = (action: () => Promise<void>) => {
    this.actionToRun = action;
  };

  public approveArtist = async () => {
    await this.client.mutate<approveArtistResponse, approveArtistVariables>({
      mutation: approveArtist,
      variables: {
        artistId: this.id,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };

  public suspendArtist = async () => {
    await this.client.mutate<suspendArtistResponse, suspendArtistVariables>({
      mutation: suspendArtist,
      variables: {
        artistId: this.id,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };

  public rejectArtist = async () => {
    await this.client.mutate<rejectArtistResponse, rejectArtistVariables>({
      mutation: rejectArtist,
      variables: {
        artistId: this.id,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };

  public featureArtist = async () => {
    await this.client.mutate<featureArtistResponse, featureArtistVariables>({
      mutation: featureArtist,
      variables: {
        id: this.id,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };

  public unfeatureArtist = async () => {
    await this.client.mutate<unfeatureArtistResponse, unfeatureArtistVariables>(
      {
        mutation: unfeatureArtist,
        variables: {
          id: this.id,
        },
        refetchQueries: [
          { query: findArtistById, variables: { artistId: this.id } },
        ],
      }
    );
  };

  public disableArtist = async () => {
    await this.client.mutate<disableArtistResponse, disableArtistVariables>({
      mutation: disableArtist,
      variables: {
        artistId: this.id,
        isActive:false,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };

  public enableArtist = async () => {
    await this.client.mutate<disableArtistResponse, disableArtistVariables>({
      mutation: disableArtist,
      variables: {
        artistId: this.id,
        isActive:true,
      },
      refetchQueries: [
        { query: findArtistById, variables: { artistId: this.id } },
      ],
    });
  };
}

export default ArtistActionMenu;
