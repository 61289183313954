import gql from 'graphql-tag';

const disabledAlbum = gql`
mutation disableAlbum($albumId:String,$disabled:Boolean){ 
    disableAlbum(albumId:$albumId,disabled:$disabled){ 
        _id
        disabled
    } 
}
`;

export interface disableAlbumResponse {
    album: Album;
}

export interface disableAlbumVariables {
    albumId: string;
    disabled: boolean;
}

export default disabledAlbum;
