import gql from 'graphql-tag';

const runJob = gql`
  mutation runJob($jobType: String) {
    response: runJob(jobType: $jobType)
  }
`;

export interface runJobResponse {
  response: string;
}

export interface runJobVariables {
  jobType: string;
}

export default runJob;
